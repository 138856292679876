import { getValueFromDto as getValue } from './_helper';
import { round } from '@/helpers';

class EstimateWorkflowSummary {
	constructor(dto) {
		this.optionalsSectionsIdsAdded = getValue(dto, 'optionalsSectionsIdsAdded', 'object', []);
		this.lastCompleteSectionId = getValue(dto, 'lastCompleteSectionId', 'number', null);
		this.discountableItemTotal = getValue(dto, 'discountableItemTotal', 'number', 0);
		this.nonDiscountableItemTotal = getValue(dto, 'nonDiscountableItemTotal', 'number', 0);
		this.discountPercent = getValue(dto, 'discountPercent', 'number', 0);
		this.promotion = getValue(dto, 'promotion', 'number', 0);
	}

	get itemTotal() {
		return this.nonDiscountableItemTotal + this.discountableItemTotal;
	}

	get discount() {
		return round(this.discountableItemTotal * this.discountPercent, 3);
	}

	get total() {
		const aux = round(this.itemTotal - this.discount, 3);
		return round(aux, 2);
	}

	get totalWithPromotion() {
		const aux = round(this.total - this.promotion, 3);
		return round(aux, 2);
	}
}

Object.defineProperty(EstimateWorkflowSummary.prototype, 'total', { enumerable: true });

export default EstimateWorkflowSummary;
