import EstimateSections from './EstimateSections';
import EstimateWorkflows from './EstimateWorkflows';

// prettier-ignore
export const fieldInfo = {
	'0.1': { dataType: 'string', property: 'customPO', name: 'Custom work', allowMultiple: true, allowQuantityChange: true, },

	'1.1': { dataType: 'dropdown', property: 'areaOfWork', name: 'Area of work', },
	'1.2': { dataType: 'number', property: 'yearsInHome', name: 'Years in home', },
	'1.3': { dataType: 'number', property: 'numberOfStories', name: '# of stories', },
	'1.4': { dataType: 'number', property: 'ageOfRoof', name: 'Age of roof', },
	'1.5': { dataType: 'dropdown', property: 'satelliteDish', name: 'Satellite dish', },
	'1.6': { dataType: 'dropdown', property: 'roofAccess', name: 'Roof access', },
	'1.7': { dataType: 'dropdown', property: 'preferredTimeline', name: 'Preferred timeline', },
	'1.8': { dataType: 'boolean', property: 'roofingProblemLeaks', name: 'Leaks', },
	'1.9': { dataType: 'boolean', property: 'roofingProblemChimneyLeaks', name: 'Chimney leaks', },
	'1.10': { dataType: 'boolean', property: 'roofingProblemValleysLeaks', name: 'Valleys leaks', },
	'1.11': { dataType: 'boolean', property: 'roofingProblemGuttersLeaks', name: 'Gutters leaks', },
	'1.12': { dataType: 'boolean', property: 'roofingProblemSkylightsLeaks', name: 'Skylights leaks', },
	'1.13': { dataType: 'boolean', property: 'roofingProblemRoofLeaks', name: 'Roof leaks', },
	'1.14': { dataType: 'boolean', property: 'roofingProblemOtherLeaks', name: 'Other leaks', },
	'1.15': { dataType: 'boolean', property: 'roofingProblemFlashingLeaks', name: 'Flashing leaks', },
	'1.16': { dataType: 'boolean', property: 'roofingProblemIceDams', name: 'Ice dams', },
	'1.17': { dataType: 'boolean', property: 'roofingProblemChimneyIceDams', name: 'Chimney ice dams', },
	'1.18': { dataType: 'boolean', property: 'roofingProblemValleysIceDams', name: 'Valleys ice dams', },
	'1.19': { dataType: 'boolean', property: 'roofingProblemGuttersIceDams', name: 'Gutters ice dams', },
	'1.20': { dataType: 'boolean', property: 'roofingProblemSkylightsIceDams', name: 'Skylights ice dams', },
	'1.21': { dataType: 'boolean', property: 'roofingProblemRoofIceDams', name: 'Roof ice dams', },
	'1.22': { dataType: 'boolean', property: 'roofingProblemOtherIceDams', name: 'Other ice dams', },
	'1.23': { dataType: 'boolean', property: 'roofingProblemOldShingles', name: 'Old shingles', },
	'1.24': { dataType: 'boolean', property: 'roofingProblemCurling', name: 'Curling', },
	'1.25': { dataType: 'boolean', property: 'roofingProblemStormDamage', name: 'Storm damage', },
	'1.26': { dataType: 'boolean', property: 'roofingProblemGranular', name: 'Granular', },
	'1.27': { dataType: 'boolean', property: 'roofingProblemFlashing', name: 'Flashing', },
	'1.28': { dataType: 'boolean', property: 'gutterProblemLeakingCorners', name: 'Leaking corners', },
	'1.29': { dataType: 'boolean', property: 'gutterProblemNotProperlyPitched', name: 'Not properly pitched', },
	'1.30': { dataType: 'boolean', property: 'gutterProblemClogging', name: 'Clogging', },
	'1.31': { dataType: 'boolean', property: 'dumpsterNeeded', name: 'Dumpster needed', defaultValue: true, },
	'1.171': { dataType: 'string', property: 'dumpster', name: 'Dumpster', defaultValue: true, allowMultiple: true, defaultOne: true, },
	'1.32': { dataType: 'dropdown', property: 'dumpsterType', name: 'Type', parentId: '1.171', },
	'1.33': { dataType: 'dropdown', property: 'dumpsterLocation', name: 'Location', parentId: '1.171', },
	'1.181': { dataType: 'dropdown', property: 'dumpsterLabor', name: 'Dumpster labor', units: 'SQ', allowQuantityChange: true, },
	'1.35': { dataType: 'string', property: 'previousRoofingProviders', name: 'Previous roofing providers', allowMultiple: true, defaultOne: true, },
	'1.166': { dataType: 'number', property: 'totalRoofArea', name: 'Total roof area', units: 'SQ FT', },
	'1.167': { dataType: 'number', property: 'wasteFactorForMaterial', name: 'Projected waste', units: '%', },
	'1.176': { dataType: 'number', property: 'wasteFactorForLabor', name: 'Labor waste factor', units: '%', },
	'1.168': { dataType: 'number', property: 'totalSquaresForMaterial', name: 'Total SQ for material', },
	'1.169': { dataType: 'number', property: 'totalSquaresForLabor', name: 'Total SQ for labor', },
	'1.184': { dataType: 'number', property: 'dumpsterWeight', name: 'Dumpster weight', units: 'TON', },
	'1.38': { dataType: 'number', property: 'squaresOfPitch0_3', name: '0-3 pitch', units: 'SQ', },
	'1.39': { dataType: 'number', property: 'squaresOfPitch4_7', name: '4-7 pitch', units: 'SQ', },
	'1.40': { dataType: 'number', property: 'squaresOfPitch8_10', name: '8-10 pitch', units: 'SQ', },
	'1.41': { dataType: 'number', property: 'squaresOfPitch11_12', name: '11-12 pitch', units: 'SQ', },
	'1.42': { dataType: 'number', property: 'squaresOfPitch13_', name: '13+ pitch', units: 'SQ', },
	'1.172': { dataType: 'number', property: 'squaresOfPitchMansard', name: 'Mansard', units: 'SQ', },
	'1.48': { dataType: 'number', property: 'squaresOnStory1', name: '1st story', units: 'SQ', },
	'1.49': { dataType: 'number', property: 'squaresOnStory2', name: '2nd story', units: 'SQ', },
	'1.50': { dataType: 'number', property: 'squaresOnStory3', name: '3rd story', units: 'SQ', },
	'1.51': { dataType: 'number', property: 'squaresOnStory4', name: '4th story', units: 'SQ', },
	'1.177': { dataType: 'string', property: 'tearOff', name: 'Tear off', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.178': { dataType: 'dropdown', property: 'tearOffLayer', name: 'Layer', parentId: '1.177', },
	'1.179': { dataType: 'dropdown', property: 'tearOffPitch', name: 'Pitch', parentId: '1.177', },
	'1.180': { dataType: 'dropdown', property: 'tearOffMaterial', name: 'Material', parentId: '1.177', },
	'1.89': { dataType: 'dropdown', property: 'materialType', name: 'Material Type', },
	'1.43': { dataType: 'dropdown', property: 'installPitch', name: 'Install Pitch', units: 'SQ', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'1.173': { dataType: 'dropdown', property: 'deckingScope', name: 'Decking scope', },
	'1.53': { dataType: 'dropdown', property: 'deckingStyle', name: 'Decking', units: 'SQ', allowQuantityChange: true, },
	'1.55': { dataType: 'dropdown', property: 'decking', name: 'Decking', units: 'SH', allowQuantityChange: true, },
	'1.54': { dataType: 'dropdown', property: 'replaceDeckBoards', name: 'Replace deck boards', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.58': { dataType: 'number', property: 'eaveLength', name: 'Eave footage', },
	'1.60': { dataType: 'number', property: 'rakeEdgeLength', name: 'Rake footage', },
	'1.63': { dataType: 'number', property: 'hipRidgeLength', name: 'Hip & ridge footage', },
	'1.189': { dataType: 'number', property: 'flashingLength', name: 'Flashing' },
	'1.37': { dataType: 'number', property: 'valleyLength', name: 'Valley footage', },
	'1.146': { dataType: 'number', property: 'roofToWallFlashingLength', name: 'Roof to wall flashing length', },
	'1.36': { dataType: 'dropdown', property: 'iceBarrierRows', name: 'Ice barrier rows', units: 'ROW', allowQuantityChange: true, },
	'1.57': { dataType: 'dropdown', property: 'iceBarrier', name: 'Ice barrier', units: 'RL', allowQuantityChange: true, },
	'1.59': { dataType: 'dropdown', property: 'underlayment', name: 'Underlayment', units: 'RL', allowQuantityChange: true, },
	'1.61': { dataType: 'dropdown', property: 'starters', name: 'Starters', units: 'BND', allowQuantityChange: true, },
	'1.62': { dataType: 'dropdown', property: 'hipRidge', name: 'Hip & ridge', units: 'BND', allowQuantityChange: true, },

	'1.143': { dataType: 'string', property: 'valleyFlashing', name: 'Valleys', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.64': { dataType: 'dropdown', property: 'valleyFlashingColor', name: 'Color', parentId: '1.143', },
	'1.78': { dataType: 'dropdown', property: 'valleyFlashingType', name: 'Type', parentId: '1.143', },
	'1.144': { dataType: 'string', property: 'gutterFlashing', name: 'Gutter apron', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.65': { dataType: 'dropdown', property: 'gutterFlashingColor', name: 'Color', parentId: '1.144', },
	'1.56': { dataType: 'dropdown', property: 'gutterFlashingType', name: 'Type', parentId: '1.144', },
	'1.145': { dataType: 'string', property: 'dripEdge', name: 'Drip edge', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.66': { dataType: 'dropdown', property: 'dripEdgeColor', name: 'Color', parentId: '1.145', },
	'1.67': { dataType: 'dropdown', property: 'dripEdgeType', name: 'Type', parentId: '1.145', },
	'1.175': { dataType: 'string', property: 'counterFlashing', name: 'Counter flashing', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.68': { dataType: 'dropdown', property: 'counterFlashingType', name: 'Type', parentId: '1.175', units: 'FT', },
	'1.69': { dataType: 'dropdown', property: 'counterFlashingColor', name: 'Color', parentId: '1.175', },
	'1.70': { dataType: 'string', property: 'headFlashing', name: 'Head flashing', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.170': { dataType: 'string', property: 'stepFlashing', name: 'Step flashing', units: 'BND', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.71': { dataType: 'dropdown', property: 'stepFlashingColor', name: 'Color', parentId: '1.170', },
	'1.84': { dataType: 'dropdown', property: 'stepFlashingType', name: 'Type', parentId: '1.170', },
	'1.188': { dataType: 'dropdown', property: 'headFlashingColor', name: 'Color', parentId: '1.70', },
	'1.186': { dataType: 'dropdown', property: 'headFlashingType', name: 'Type', parentId: '1.70', },
	'1.72': { dataType: 'dropdown', property: 'pvcBoot', name: 'PVC pipe flashing', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.73': { dataType: 'dropdown', property: 'leadBoot', name: 'Lead pipe flashing', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.74': { dataType: 'dropdown', property: 'zipperBoot', name: 'Zipper pipe flashing', allowQuantityChange: true, },
	'1.802': { dataType: 'string', property: 'roofToWallTrim', name: 'Roof to wall trim', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.803': { dataType: 'number', property: 'roofToWallTrimArea', name: 'Roof to wall trim area', units: 'FT', parentId: '1.802', },
	'1.804': { dataType: 'dropdown', property: 'roofToWallTrimType', name: 'Roof to wall trim type', units: 'PC', parentId: '1.802', allowQuantityChange: true, },

	'1.75': { dataType: 'dropdown', property: 'chimneyFlashing', name: 'Chimney flashing', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.86': { dataType: 'dropdown', property: 'chimneyFlashingColor', name: 'Chimney flashing color', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.79': { dataType: 'dropdown', property: 'chimneyChaseCap', name: 'Chimney chase cap', allowQuantityChange: true, },
	'1.80': { dataType: 'dropdown', property: 'tuckPointChimney', name: 'Tuck point chimney', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.82': { dataType: 'number', property: 'chimneyMasonrySealer', name: 'Masonry sealer', },
	'1.130': { dataType: 'dropdown', property: 'removeChimney', name: 'Remove chimney', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'1.83': { dataType: 'dropdown', property: 'curbMountedReplace', name: 'Curb mounted skylight', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.85': { dataType: 'dropdown', property: 'deckMountedReplace', name: 'Deck mounted skylight', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.87': { dataType: 'dropdown', property: 'sunTunnelReplace', name: 'Sun tunnel skylight', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.88': { dataType: 'number', property: 'curbMountedReFlash', name: 'Re-flash curb mounted skylight', },
	'1.90': { dataType: 'number', property: 'deckMountedReFlash', name: 'Re-flash deck mounted skylight', },
	'1.91': { dataType: 'number', property: 'curbMountedCutIn', name: 'Cut in curb mounted skylight', },
	'1.92': { dataType: 'number', property: 'deckMountedCutIn', name: 'Cut in deck mounted skylight', },
	'1.93': { dataType: 'number', property: 'curbMountedSolarBlinds', name: 'Solar blinds for curb mounted skylight', },
	'1.94': { dataType: 'number', property: 'deckMountedSolarBlinds', name: 'Solar blinds for deck mounted skylight', },
	'1.182': { dataType: 'number', property: 'buildCurb', name: 'Build curb', },

	'1.97': { dataType: 'number', property: 'atticSpaceVented', name: 'Attic space to vent', units: 'SQ FT', },
	'1.77': { dataType: 'number', property: 'ventRidgeLength', name: 'Ridge length', units: 'FT', },
	'1.81': { dataType: 'number', property: 'ventEaveLength', name: 'Eave length', units: 'FT', },
	'1.98': { dataType: 'dropdown', property: 'fasciaIntakeVent', name: 'Fascia intake vent', units: 'PC', allowQuantityChange: true, },
	'1.201': { dataType: 'number', property: 'fasciaVentCutInLength', name: 'Fascia cut in length', units: 'FT' },
	'1.163': { dataType: 'number', property: 'fasciaVentLength', name: 'Fascia vent length', units: 'FT', },
	'1.99': { dataType: 'dropdown', property: 'louveredSoffitVentAluminum', name: 'Louvered 4″x16″ intake vent', allowQuantityChange: true, },
	'1.100': { dataType: 'dropdown', property: 'louveredSoffitVentWood', name: 'Louvered 8″x16″ intake vent', allowQuantityChange: true, },
	'1.805': { dataType: 'string', property: 'edgeVent', name: 'EdgeVent', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.101': { dataType: 'dropdown', property: 'edgeVentType', name: 'EdgeVent type', units: 'PC', parentId: '1.805', allowQuantityChange: true, },
	'1.204': { dataType: 'number', property: 'edgeVentCutInLength', name: 'EdgeVent cut in length', units: 'FT', parentId: '1.805', },
	'1.164': { dataType: 'number', property: 'edgeVentLength', name: 'EdgeVent length', units: 'FT', parentId: '1.805', },
	'1.806': { dataType: 'string', property: 'ridgeExhaustVent', name: 'Ridge exhaust vent', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.102': { dataType: 'dropdown', property: 'ridgeExhaustVentType', name: 'Ridge exhaust vent type', units: 'PC', parentId: '1.806', allowQuantityChange: true },
	'1.161': { dataType: 'number', property: 'ridgeVentCutInLength', name: 'Ridge vent cut in', units: 'FT', parentId: '1.806', },
	'1.165': { dataType: 'number', property: 'ridgeVentLength', name: 'Ridge vent length', units: 'FT', parentId: '1.806', },
	'1.103': { dataType: 'dropdown', property: 'powerExhaustVent', name: 'Power vent', allowQuantityChange: true, },
	'1.104': { dataType: 'dropdown', property: 'boxExhaustVent', name: 'Box vent', allowQuantityChange: true, },
	'1.105': { dataType: 'dropdown', property: 'kitchenExhaustVent', name: 'Kitchen exhaust vent', allowQuantityChange: true, },
	'1.106': { dataType: 'dropdown', property: 'bathExhaustVent', name: 'Bathroom exhaust vent', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.115': { dataType: 'dropdown', property: 'patch', name: 'Patch', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'1.210': { dataType: 'string', property: 'removal', name: 'Removal', units: 'squares', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.211': { dataType: 'dropdown', property: 'removalType', name: 'Type', parentId: '1.210', },
	'1.212': { dataType: 'dropdown', property: 'removalLayers', name: 'Layers', parentId: '1.210', },
	'1.228': { dataType: 'string', property: 'membrane', name: 'Membrane', allowMultiple: true, defaultOne: true, },
	'1.229': { dataType: 'number', property: 'membraneArea', name: 'Area', parentId: '1.228', units: 'SQ FT', },
	'1.224': { dataType: 'number', property: 'membraneWasteFactorForMaterial', name: 'Material waste', parentId: '1.228', units: '%', },
	'1.225': { dataType: 'number', property: 'membraneWasteFactorForLabor', name: 'Labor waste', parentId: '1.228', units: '%', },
	'1.226': { dataType: 'dropdown', property: 'membraneLabor', name: 'Labor', parentId: '1.228', units: 'SQ', allowQuantityChange: true, },
	'1.213': { dataType: 'dropdown', property: 'membraneType', name: 'Type', parentId: '1.228', units: 'SQ', allowQuantityChange: true, },
	'1.108': { dataType: 'dropdown', property: 'membraneColor', name: 'Color', parentId: '1.228', },
	'1.114': { dataType: 'dropdown', property: 'insulation', name: 'Insulation', units: 'SH', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.214': { dataType: 'string', property: 'edgeDetail', name: 'Edge detail', allowMultiple: true, defaultOne: true, },
	'1.209': { dataType: 'number', property: 'edgeDetailLength', name: 'Edge detail length', units: 'FT', parentId: '1.214', },
	'1.215': { dataType: 'dropdown', property: 'edgeDetailType', name: 'Edge detail type', units: 'PC', allowQuantityChange: true, parentId: '1.214', },
	'1.216': { dataType: 'dropdown', property: 'corner', name: 'Corner / T joint', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.217': { dataType: 'dropdown', property: 'pipeBoot', name: 'Pipe boot', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.218': { dataType: 'dropdown', property: 'coverTape', name: 'Cover tape / accessories', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.219': { dataType: 'dropdown', property: 'drain', name: 'Drain', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.220': { dataType: 'dropdown', property: 'adhesive', name: 'Bonding adhesive', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.223': { dataType: 'dropdown', property: 'sealants', name: 'Sealants', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.221': { dataType: 'dropdown', property: 'plate', name: 'Plate', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.222': { dataType: 'dropdown', property: 'screws', name: 'Screws', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.227': { dataType: 'dropdown', property: 'applicationTools', name: 'Application tools', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.120': { dataType: 'dropdown', property: 'railingSections', name: 'Flat roof railings', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.123': { dataType: 'dropdown', property: 'railingPosts', name: 'Flat roof railing posts', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.807': { dataType: 'string', property: 'standardSoffit', name: 'Standard soffit', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.808': { dataType: 'number', property: 'standardSoffitDepth', name: 'Depth', parentId: '1.807', units: 'IN', },
	'1.809': { dataType: 'number', property: 'standardSoffitLength', name: 'Length', parentId: '1.807', units: 'FT', },
	'1.810': { dataType: 'string', property: 'standardSoffitColor', name: 'Color', parentId: '1.807', },
	'1.811': { dataType: 'dropdown', property: 'standardSoffitLabor', name: 'Labor', parentId: '1.807', },
	'1.812': { dataType: 'string', property: 'hiddenVentSoffit', name: 'Hidden vent soffit', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.813': { dataType: 'number', property: 'hiddenVentSoffitDepth', name: 'Depth', parentId: '1.812', units: 'IN', },
	'1.814': { dataType: 'number', property: 'hiddenVentSoffitLength', name: 'Length', parentId: '1.812', units: 'FT', },
	'1.815': { dataType: 'string', property: 'hiddenVentSoffitColor', name: 'Color', parentId: '1.812', },
	'1.816': { dataType: 'dropdown', property: 'hiddenVentSoffitLabor', name: 'Labor', parentId: '1.812', },
	'1.817': { dataType: 'string', property: 'porchSoffit', name: 'Porch soffit', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.818': { dataType: 'number', property: 'porchSoffitDepth', name: 'Depth', parentId: '1.817', units: 'FT', },
	'1.819': { dataType: 'number', property: 'porchSoffitLength', name: 'Length', parentId: '1.817', units: 'FT', },
	'1.820': { dataType: 'string', property: 'porchSoffitColor', name: 'Color', parentId: '1.817', },
	'1.821': { dataType: 'dropdown', property: 'porchSoffitLabor', name: 'Labor', parentId: '1.817', },
	'1.822': { dataType: 'string', property: 'soffit', name: 'Soffit', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.823': { dataType: 'dropdown', property: 'soffitType', name: 'Type', parentId: '1.822', },
	'1.824': { dataType: 'dropdown', property: 'soffitColor', name: 'Color', parentId: '1.822', },
	'1.825': { dataType: 'dropdown', property: 'jChannel', name: 'J channel', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.826': { dataType: 'string', property: 'compositeSoffit', name: 'Composite soffit', allowMultiple: true, defaultOne: true, },
	'1.827': { dataType: 'number', property: 'compositeSoffitDepth', name: 'Depth', parentId: '1.826', units: 'IN', },
	'1.828': { dataType: 'dropdown', property: 'compositeSoffitLength', name: 'Length', parentId: '1.826', units: 'FT', allowQuantityChange: true, },
	'1.829': { dataType: 'dropdown', property: 'compositeSoffitType', name: 'Type', parentId: '1.826', units: 'PC', allowQuantityChange: true, },
	'1.830': { dataType: 'dropdown', property: 'compositeSoffitColor', name: 'Color', parentId: '1.826', },
	'1.831': { dataType: 'dropdown', property: 'cutSoffitVent', name: 'Cut in soffit vent', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	
	'1.832': { dataType: 'string', property: 'fasciaWrap', name: 'Aluminum fascia', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.833': { dataType: 'dropdown', property: 'fasciaWrapWidth', name: 'Width', parentId: '1.832', },
	'1.834': { dataType: 'dropdown', property: 'fasciaWrapColor', name: 'Color', parentId: '1.832', },
	'1.835': { dataType: 'string', property: 'fascia', name: 'Fascia', allowMultiple: true, defaultOne: true, },
	'1.837': { dataType: 'dropdown', property: 'fasciaType', name: 'Type', parentId: '1.835', units: 'PC', allowQuantityChange: true, },
	'1.838': { dataType: 'dropdown', property: 'fasciaColor', name: 'Color', parentId: '1.835', },
	'1.836': { dataType: 'number', property: 'fasciaLength', name: 'Length', parentId: '1.835', units: 'FT', },
	'1.126': { dataType: 'number', property: 'gutterIceBarrier', name: 'Install ice & water barrier behind gutters', units: 'FT', },
	'1.127': { dataType: 'number', property: 'atticBaffles', name: 'Install attic baffles', },
	'1.129': { dataType: 'boolean', property: 'tarpAttic', name: 'Tarp attic', },
	'1.136': { dataType: 'boolean', property: 'permit', name: 'Roofing permit', },
	'1.137': { dataType: 'dropdown', property: 'caulk', name: 'Caulk', units: 'TB', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.138': { dataType: 'dropdown', property: 'roofingCement', name: 'Roofing cement', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.142': { dataType: 'string', property: 'trimCoil', name: 'Trim coil', allowMultiple: true, defaultOne: true, units: 'RL', allowQuantityChange: true, },
	'1.139': { dataType: 'dropdown', property: 'trimCoilType', name: 'Type', parentId: '1.142', },
	'1.140': { dataType: 'dropdown', property: 'trimCoilColor', name: 'Color', parentId: '1.142', },
	'1.174': { dataType: 'dropdown', property: 'fasteners', name: 'Fasteners', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.183': { dataType: 'dropdown', property: 'corniceReturns', name: 'Eyebrow / Cornice Returns', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.131': { dataType: 'string', property: 'custom', name: 'Custom work', allowPriceChange: true, allowMultiple: true, allowQuantityChange: true, },
	'1.800': { dataType: 'dropdown', property: 'deliveryDistance', name: 'Delivery / Distance', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.801': { dataType: 'dropdown', property: 'handLoading', name: 'Hand Loading', units: 'SQ', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.839': { dataType: 'dropdown', property: 'soffitLightingType', name: 'Soffit Lighting Type', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'1.840': { dataType: 'dropdown', property: 'trackLightingType', name: 'Track Lighting Type', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'1.147': { dataType: 'dropdown', property: 'productionDays', name: 'Production days', },
	'1.148': { dataType: 'dropdown', property: 'expectations', name: 'Expectations', },
	'1.149': { dataType: 'dropdown', property: 'paymentTerms', name: 'Payment terms', },
	'1.150': { dataType: 'dropdown', property: 'materialsOnSite', name: 'Materials on site', },
	'1.151': { dataType: 'dropdown', property: 'materialDeliverySchedule', name: 'Delivery schedule', },
	'1.152': { dataType: 'dropdown', property: 'warranty', name: 'Warranty', allowQuantityChange: true, },
	'1.153': { dataType: 'dropdown', property: 'predominantRoofSlope', name: 'Predominant roof slope', },
	'1.154': { dataType: 'dropdown', property: 'existingRoofCondition', name: 'Existing roof condition', },
	'1.155': { dataType: 'number', property: 'existingRoofLayers', name: 'Existing layers on roof', },
	'1.156': { dataType: 'dropdown', property: 'gutterAction', name: 'Gutter action', },
	'1.157': { dataType: 'dropdown', property: 'iceBarrierLocation', name: 'Ice barrier location', },
	'1.158': { dataType: 'dropdown', property: 'headFlashingInstallType', name: 'Head flashing install type', },
	'1.159': { dataType: 'dropdown', property: 'roofToWallFlashingInstallType', name: 'Roof to wall flashing install type', },
	'1.160': { dataType: 'dropdown', property: 'counterFlashingInstallType', name: 'Counter flashing install type', },
	'1.162': { dataType: 'dropdown', property: 'sidingStyle', name: 'Siding style', },
	'1.187': { dataType: 'dropdown', property: 'existingDeckType', name: 'Existing Deck Type', placeHolderId: '1.187.1'},

	'1.132': { dataType: 'dropdown', property: 'package', name: 'Shingle type', defaultValue: null, isComparison: true, allowQuantityChange: true, units: 'SQ', allowNull: true, },
	'1.134': { dataType: 'dropdown', property: 'packageInstallation', name: 'Roofing installation', allowQuantityChange: true, units: 'SQ', },
	'1.133': { dataType: 'dropdown', property: 'shingleColor', name: 'Shingle color', allowQuantityChange: true, units: 'SQ', },
	
	'1.998': { dataType: 'string', property: 'notes', name: 'Roofing notes', },
	'1.999': { dataType: 'number', property: 'discount', name: 'Roofing discount', },

	'2.1': { dataType: 'dropdown', property: 'areaOfWork', name: 'Area of work', },
	'2.2': { dataType: 'number', property: 'yearsInHome', name: 'Years in home', },
	'2.3': { dataType: 'number', property: 'numberOfStories', name: '# of stories', },
	'2.4': { dataType: 'number', property: 'ageOfSiding', name: 'Age of siding', },
	'2.5': { dataType: 'dropdown', property: 'sidingAccess', name: 'Siding access', },
	'2.6': { dataType: 'dropdown', property: 'preferredTimeline', name: 'Preferred timeline', },
	'2.7': { dataType: 'dropdown', property: 'energyEfficiency', name: 'Energy efficiency', },
	'2.8': { dataType: 'dropdown', property: 'warranty', name: 'Warranty', },
	'2.9': { dataType: 'dropdown', property: 'decidingFactor', name: 'Deciding factor', },
	'2.10': { dataType: 'dropdown', property: 'budget', name: 'Budget', },
	'2.11': { dataType: 'boolean', property: 'sidingProblemPoorCurbAppeal', name: 'Poor curb appeal', },
	'2.12': { dataType: 'boolean', property: 'sidingProblemMaintenance', name: 'Maintenance', },
	'2.13': { dataType: 'boolean', property: 'sidingProblemStormDamage', name: 'Storm damage', },
	'2.14': { dataType: 'boolean', property: 'sidingProblemPoorlyInsulated', name: 'Poorly insulated', },
	'2.15': { dataType: 'boolean', property: 'sidingProblemNoPaint', name: 'No paint', },
	'2.16': { dataType: 'boolean', property: 'gutterProblemLeakingCorners', name: 'Leaking corners', },
	'2.17': { dataType: 'boolean', property: 'gutterProblemNotProperlyPitched', name: 'Not properly pitched', },
	'2.18': { dataType: 'boolean', property: 'gutterProblemClogging', name: 'Clogging', },
	'2.19': { dataType: 'boolean', property: 'sidingInterestBasicVinyl', name: 'Basic vinyl', },
	'2.20': { dataType: 'boolean', property: 'sidingInterestInsulatedVinyl', name: 'Insulated vinyl', },
	'2.21': { dataType: 'boolean', property: 'sidingInterestHardBoard', name: 'Hard board', },
	'2.22': { dataType: 'boolean', property: 'sidingInterestCedar', name: 'Cedar', },

	'2.23': { dataType: 'dropdown', property: 'existingSidingStyle', name: 'Existing siding style', },
	'2.24': { dataType: 'string', property: 'previousSidingProviders', name: 'Previous siding providers', allowMultiple: true, defaultOne: true, },
	'2.28': { dataType: 'dropdown', property: 'sidingInstallType', name: 'Siding install type', },
	'2.187': { dataType: 'dropdown', property: 'materialType', name: 'Material Type', },
	'2.98': { dataType: 'number', property: 'totalSidingArea', name: 'Total siding area', units: 'SQ FT', },
	'2.99': { dataType: 'number', property: 'wasteFactorForMaterial', name: 'Projected waste', units: '%', },
	'2.116': { dataType: 'number', property: 'wasteFactorForLabor', name: 'Labor waste factor', units: '%', },
	'2.111': { dataType: 'number', property: 'totalSquaresForMaterial', name: 'Total material', units: 'SQ', },
	'2.112': { dataType: 'number', property: 'totalSquaresForLabor', name: 'Total labor', units: 'SQ', },
	'2.29': { dataType: 'number', property: 'totalSquaresForMaterialPackage', name: 'Total package material', units: 'SQ', },
	'2.100': { dataType: 'number', property: 'totalSquaresForLaborPackage', name: 'Total package labor', units: 'SQ', },
	'2.30': { dataType: 'dropdown', property: 'tearOff', name: 'Tear off existing siding', units: 'SQ', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.31': { dataType: 'boolean', property: 'dumpsterNeeded', name: 'Dumpster needed', defaultValue: true, },
	'2.114': { dataType: 'string', property: 'dumpster', name: 'Dumpster', defaultValue: true, allowMultiple: true, defaultOne: true, },
	'2.32': { dataType: 'dropdown', property: 'dumpsterType', name: 'Type', parentId: '2.114', },
	'2.33': { dataType: 'dropdown', property: 'dumpsterLocation', name: 'Location', parentId: '2.114', },
	'2.125': { dataType: 'dropdown', property: 'dumpsterLabor', name: 'Dumpster labor', units: 'SQ', allowQuantityChange: true, },
	'2.129': { dataType: 'number', property: 'dumpsterWeight', name: 'Dumpster weight', units: 'TON' },
	'2.119': { dataType: 'string', property: 'sheathing', name: 'Sheathing', allowMultiple: true, defaultOne: true, },
	'2.120': { dataType: 'number', property: 'sheathingArea', name: 'Area', parentId: '2.119', units: 'SQ', },
	'2.121': { dataType: 'dropdown', property: 'sheathingType', name: 'Type', parentId: '2.119', units: 'SH', allowQuantityChange: true, },
	'2.122': { dataType: 'string', property: 'boards', name: 'Board', allowMultiple: true, defaultOne: true, },
	'2.123': { dataType: 'number', property: 'boardLength', name: 'Length', parentId: '2.122', units: 'FT', },
	'2.124': { dataType: 'dropdown', property: 'boardType', name: 'Type', parentId: '2.122', units: 'PC', allowQuantityChange: true, },

	'2.108': { dataType: 'string', property: 'houseWrap', name: 'House wrap', allowMultiple: true, defaultOne: true, },
	'2.109': { dataType: 'dropdown', property: 'houseWrapLabor', name: 'Labor', parentId: '2.108', units: 'SQ', allowQuantityChange: true, },
	'2.110': { dataType: 'number', property: 'houseWrapMaterial', name: 'Material', parentId: '2.108', units: 'SQ', },
	'2.34': { dataType: 'dropdown', property: 'houseWrapType', name: 'Type', parentId: '2.108', units: 'RL', allowQuantityChange: true, },
	'2.130': { dataType: 'string', property: 'houseInsulation', name: 'Insulation', allowMultiple: true, defaultOne: true, },
	'2.131': { dataType: 'dropdown', property: 'houseInsulationLabor', name: 'Labor', parentId: '2.130', units: 'SQ', allowQuantityChange: true, },
	'2.132': { dataType: 'number', property: 'houseInsulationMaterial', name: 'Material', parentId: '2.130', units: 'SQ', },
	'2.133': { dataType: 'dropdown', property: 'houseInsulationType', name: 'Type', parentId: '2.130', units: 'BND', allowQuantityChange: true, },

	'2.841': { dataType: 'string', property: 'jChannel', name: 'J channel', allowMultiple: true, defaultOne: true, },
	'2.35': { dataType: 'dropdown', property: 'jChannelType', name: 'Type', units: 'PC', parentId: '2.841', allowQuantityChange: true, },
	'2.842': { dataType: 'number', property: 'jChannelLength', name: 'Length', units: 'FT', parentId: '2.841', allowQuantityChange: true, },
	'2.843': { dataType: 'string', property: 'fChannel', name: 'F channel', allowMultiple: true, defaultOne: true, },
	'2.36': { dataType: 'dropdown', property: 'fChannelType', name: 'Type', units: 'PC', parentId: '2.843', allowQuantityChange: true, },
	'2.844': { dataType: 'number', property: 'fChannelLength', name: 'Length', units: 'FT', parentId: '2.843', allowQuantityChange: true, },
	'2.845': { dataType: 'string', property: 'utilityChannel', name: 'Utility channel', allowMultiple: true, defaultOne: true, },
	'2.37': { dataType: 'dropdown', property: 'utilityChannelType', name: 'Type', units: 'PC', parentId: '2.845', allowQuantityChange: true, },
	'2.846': { dataType: 'number', property: 'utilityChannelLength', name: 'Length', units: 'FT', parentId: '2.845', allowQuantityChange: true, },
	'2.847': { dataType: 'string', property: 'starters', name: 'Starters', allowMultiple: true, defaultOne: true, },
	'2.38': { dataType: 'dropdown', property: 'startersType', name: 'Type', units: 'PC', parentId: '2.847', allowQuantityChange: true, },
	'2.848': { dataType: 'number', property: 'startersLength', name: 'Length', units: 'FT', parentId: '2.847', allowQuantityChange: true, },
	'2.39': { dataType: 'dropdown', property: 'masterBlocks', name: 'Master block', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.40': { dataType: 'dropdown', property: 'splitBlocks', name: 'Split block', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.41': { dataType: 'dropdown', property: 'dryerExhaustBlock', name: 'Dryer exhaust mounting block', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.42': { dataType: 'dropdown', property: 'mountingBlockColor', name: 'Mounting block color', },

	'2.43': { dataType: 'string', property: 'outsideCornerPost', name: 'Outside corner post', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.849': { dataType: 'number', property: 'outsideCornerPostLength', name: 'Length', units: 'FT', parentId: '2.43', allowQuantityChange: true, },
	'2.44': { dataType: 'dropdown', property: 'outsideCornerPostType', name: 'Type', parentId: '2.43', },
	'2.45': { dataType: 'dropdown', property: 'outsideCornerPostColor', name: 'Color', parentId: '2.43', },
	'2.46': { dataType: 'string', property: 'insideCornerPost', name: 'Inside corner post', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.850': { dataType: 'number', property: 'insideCornerPostLength', name: 'Length', units: 'FT', parentId: '2.46', allowQuantityChange: true, },
	'2.47': { dataType: 'dropdown', property: 'insideCornerPostType', name: 'Type', parentId: '2.46', },
	'2.48': { dataType: 'dropdown', property: 'insideCornerPostColor', name: 'Color', parentId: '2.46', },

	'2.49': { dataType: 'string', property: 'windowCasing', name: 'Window casing', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.50': { dataType: 'dropdown', property: 'windowCasingType', name: 'Type', parentId: '2.49', },
	'2.51': { dataType: 'dropdown', property: 'windowCasingColor', name: 'Color', parentId: '2.49', },
	'2.52': { dataType: 'string', property: 'garageDoorCasing', name: 'Garage door casing', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.53': { dataType: 'dropdown', property: 'garageDoorCasingType', name: 'Type', parentId: '2.52', },
	'2.54': { dataType: 'dropdown', property: 'garageDoorCasingColor', name: 'Color', parentId: '2.52', },
	'2.55': { dataType: 'string', property: 'garageDoorJamb', name: 'Garage door jamb + weather stripping', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.56': { dataType: 'dropdown', property: 'garageDoorJambType', name: 'Type', parentId: '2.55', },
	'2.57': { dataType: 'dropdown', property: 'garageDoorJambColor', name: 'Color', parentId: '2.55', },
	'2.58': { dataType: 'string', property: 'serviceDoorCasing', name: 'Service door casing', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.59': { dataType: 'dropdown', property: 'serviceDoorCasingType', name: 'Type', parentId: '2.58', },
	'2.60': { dataType: 'dropdown', property: 'serviceDoorCasingColor', name: 'Color', parentId: '2.58', },
	'2.651': { dataType: 'string', property: 'wallFlashing', name: 'Wall flashing', allowMultiple: true, defaultOne: true, },
	'2.65': { dataType: 'number', property: 'wallFlashingQuantity', name: 'Length', units: 'FT', parentId: '2.651', },
	'2.96': { dataType: 'dropdown', property: 'wallFlashingType', name: 'Type', parentId: '2.651', allowQuantityChange: true, },
	'2.97': { dataType: 'dropdown', property: 'wallFlashingColor', name: 'Color', parentId: '2.651', },
	'2.126': { dataType: 'string', property: 'customWrap', name: 'Custom wrap', allowQuantityChange: true, defaultOne: true, allowMultiple: true, },
	'2.127': { dataType: 'dropdown', property: 'customWrapType', name: 'Type', parentId: '2.126', },
	'2.128': { dataType: 'dropdown', property: 'customWrapColor', name: 'Color', parentId: '2.126', },
	'2.807': { dataType: 'string', property: 'standardSoffit', name: 'Standard soffit', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.808': { dataType: 'number', property: 'standardSoffitDepth', name: 'Depth', parentId: '2.807', units: 'IN', },
	'2.809': { dataType: 'number', property: 'standardSoffitLength', name: 'Length', parentId: '2.807', units: 'FT', },
	'2.810': { dataType: 'string', property: 'standardSoffitColor', name: 'Color', parentId: '2.807', },
	'2.811': { dataType: 'dropdown', property: 'standardSoffitLabor', name: 'Labor', parentId: '2.807', },
	'2.812': { dataType: 'string', property: 'hiddenVentSoffit', name: 'Hidden vent soffit', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.813': { dataType: 'number', property: 'hiddenVentSoffitDepth', name: 'Depth', parentId: '2.812', units: 'IN', },
	'2.814': { dataType: 'number', property: 'hiddenVentSoffitLength', name: 'Length', parentId: '2.812', units: 'FT', },
	'2.815': { dataType: 'string', property: 'hiddenVentSoffitColor', name: 'Color', parentId: '2.812', },
	'2.816': { dataType: 'dropdown', property: 'hiddenVentSoffitLabor', name: 'Labor', parentId: '2.812', },
	'2.817': { dataType: 'string', property: 'porchSoffit', name: 'Porch soffit', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.818': { dataType: 'number', property: 'porchSoffitDepth', name: 'Depth', parentId: '2.817', units: 'FT', },
	'2.819': { dataType: 'number', property: 'porchSoffitLength', name: 'Length', parentId: '2.817', units: 'FT', },
	'2.820': { dataType: 'string', property: 'porchSoffitColor', name: 'Color', parentId: '2.817', },
	'2.821': { dataType: 'dropdown', property: 'porchSoffitLabor', name: 'Labor', parentId: '2.817', },
	'2.822': { dataType: 'string', property: 'soffit', name: 'Soffit', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.823': { dataType: 'dropdown', property: 'soffitType', name: 'Type', parentId: '2.822', },
	'2.824': { dataType: 'dropdown', property: 'soffitColor', name: 'Color', parentId: '2.822', },
	'2.825': { dataType: 'dropdown', property: 'jChannel', name: 'J channel', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.826': { dataType: 'string', property: 'compositeSoffit', name: 'Composite soffit', allowMultiple: true, defaultOne: true, },
	'2.827': { dataType: 'number', property: 'compositeSoffitDepth', name: 'Depth', parentId: '2.826', units: 'IN', },
	'2.828': { dataType: 'dropdown', property: 'compositeSoffitLength', name: 'Length', parentId: '2.826', units: 'FT', allowQuantityChange: true, },
	'2.829': { dataType: 'dropdown', property: 'compositeSoffitType', name: 'Type', parentId: '2.826', units: 'PC', allowQuantityChange: true, },
	'2.830': { dataType: 'dropdown', property: 'compositeSoffitColor', name: 'Color', parentId: '2.826', },
	'2.831': { dataType: 'dropdown', property: 'cutSoffitVent', name: 'Cut in soffit vent', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	
	'2.832': { dataType: 'string', property: 'fasciaWrap', name: 'Aluminum fascia', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.833': { dataType: 'dropdown', property: 'fasciaWrapWidth', name: 'Width', parentId: '2.832', },
	'2.834': { dataType: 'dropdown', property: 'fasciaWrapColor', name: 'Color', parentId: '2.832', },
	'2.835': { dataType: 'string', property: 'fascia', name: 'Fascia', allowMultiple: true, defaultOne: true, },
	'2.837': { dataType: 'dropdown', property: 'fasciaType', name: 'Type', parentId: '2.835', units: 'PC', allowQuantityChange: true, },
	'2.838': { dataType: 'dropdown', property: 'fasciaColor', name: 'Color', parentId: '2.835', },
	'2.836': { dataType: 'number', property: 'fasciaLength', name: 'Length', parentId: '2.835', units: 'FT', },
	'2.61': { dataType: 'string', property: 'trim', name: 'Trim', allowMultiple: true, defaultOne: true, },
	'2.62': { dataType: 'dropdown', property: 'trimType', name: 'Type', parentId: '2.61', },
	'2.63': { dataType: 'dropdown', property: 'trimSize', name: 'Size', parentId: '2.61', units: 'PC', allowQuantityChange: true, },
	'2.64': { dataType: 'dropdown', property: 'trimColor', name: 'Color', parentId: '2.61', },
	'2.113': { dataType: 'number', property: 'trimLength', name: 'Length', parentId: '2.61', units: 'FT', },

	'2.105': { dataType: 'string', property: 'trimCoil', name: 'Trim coil', allowMultiple: true, defaultOne: true, units: 'RL', allowQuantityChange: true, },
	'2.106': { dataType: 'dropdown', property: 'trimCoilType', name: 'Type', parentId: '2.105', },
	'2.107': { dataType: 'dropdown', property: 'trimCoilColor', name: 'Color', parentId: '2.105', },

	'2.66': { dataType: 'dropdown', property: 'shutterInstallType', name: 'Shutter install type', },
	'2.67': { dataType: 'string', property: 'shutters', name: 'Shutter', units: 'Sets', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.68': { dataType: 'dropdown', property: 'shutterType', name: 'Type', parentId: '2.67', },
	'2.69': { dataType: 'dropdown', property: 'shutterColor', name: 'Color', parentId: '2.67', },

	'2.70': { dataType: 'string', property: 'gableAccents', name: 'Gable accent', allowMultiple: true, defaultOne: true, },
	'2.101': { dataType: 'number', property: 'gableAccentArea', name: 'Area', parentId: '2.70', units: 'SQ FT', },
	'2.102': { dataType: 'number', property: 'gableAccentWasteFactorForMaterial', name: 'Material waste', parentId: '2.70', units: '%', },
	'2.117': { dataType: 'number', property: 'gableAccentWasteFactorForLabor', name: 'Labor waste', parentId: '2.70', units: '%', },
	'2.103': { dataType: 'dropdown', property: 'gableAccentLabor', name: 'Labor', parentId: '2.70', units: 'SQ', allowQuantityChange: true, },
	'2.71': { dataType: 'dropdown', property: 'gableAccentType', name: 'Type', parentId: '2.70', units: 'SQ', allowQuantityChange: true, },
	'2.72': { dataType: 'dropdown', property: 'gableAccentColor', name: 'Color', parentId: '2.70', },
	'2.731': { dataType: 'string', property: 'gableVent', name: 'Gable vent', allowMultiple: true, defaultOne: true, },
	'2.73': { dataType: 'dropdown', property: 'gableVentType', name: 'Gable vent type', units: 'PC', parentId: '2.731',  allowQuantityChange: true, },
	'2.732': { dataType: 'dropdown', property: 'gableVentColor', name: 'Gable vent color', parentId: '2.731', },
	'2.118': { dataType: 'dropdown', property: 'posts', name: 'Post', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'2.74': { dataType: 'string', property: 'garageDoors', name: 'Garage door', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.75': { dataType: 'dropdown', property: 'garageDoorType', name: 'Type', parentId: '2.74', },
	'2.76': { dataType: 'dropdown', property: 'garageDoorSize', name: 'Size', parentId: '2.74', },
	'2.77': { dataType: 'dropdown', property: 'garageDoorColor', name: 'Color', parentId: '2.74', },
	'2.78': { dataType: 'dropdown', property: 'garageDoorOpener', name: 'Garage door opener', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.79': { dataType: 'string', property: 'serviceDoors', name: 'Service door', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.80': { dataType: 'dropdown', property: 'serviceDoorType', name: 'Type', parentId: '2.79', },
	'2.81': { dataType: 'dropdown', property: 'serviceDoorColor', name: 'Color', parentId: '2.79', },

	'2.82': { dataType: 'string', property: 'custom', name: 'Custom work', allowPriceChange: true, allowMultiple: true, allowQuantityChange: true, },
	'2.83': { dataType: 'dropdown', property: 'houseWrapTape', name: 'House wrap tape', units: 'RL', allowQuantityChange: true, },
	'2.84': { dataType: 'dropdown', property: 'windowFlashingTape', name: 'Window flashing tape', units: 'RL', allowQuantityChange: true, },
	'2.85': { dataType: 'dropdown', property: 'nails', name: 'Nails', allowQuantityChange: true, allowMultiple: true, defaultOne: true },
	'2.86': { dataType: 'dropdown', property: 'caulk', name: 'Caulk', units: 'TB', allowQuantityChange: true, allowMultiple: true, defaultOne: true },
	'2.115': { dataType: 'dropdown', property: 'setupFee', name: 'Setup fee', allowQuantityChange: true, allowMultiple: true, defaultOne: true },

	'2.88': { dataType: 'dropdown', property: 'sidingPackage', name: 'Siding type', defaultValue: null, isComparison: true, allowQuantityChange: true, units: 'SQ', allowNull: true, },
	'2.104': { dataType: 'dropdown', property: 'sidingPackageInstallation', name: 'Installation labor', allowQuantityChange: true, units: 'SQ', },
	'2.89': { dataType: 'dropdown', property: 'sidingColor', name: 'Siding color', allowQuantityChange: true, units: 'SQ', },
	'2.90': { dataType: 'dropdown', property: 'sidingReveal', name: 'Siding reveal', allowQuantityChange: true, units: 'SQ', },

	'2.91': { dataType: 'dropdown', property: 'productionDays', name: 'Production days', },
	'2.92': { dataType: 'dropdown', property: 'expectations', name: 'Expectations', },
	'2.93': { dataType: 'dropdown', property: 'paymentTerms', name: 'Payment terms', },
	'2.94': { dataType: 'dropdown', property: 'materialsOnSite', name: 'Materials on site', },
	'2.95': { dataType: 'dropdown', property: 'materialDeliverySchedule', name: 'Delivery schedule', },
	'2.136': { dataType: 'boolean', property: 'permit', name: 'Siding permit', },
	'2.800': { dataType: 'dropdown', property: 'deliveryDistance', name: 'Delivery / Distance', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'2.183': { dataType: 'dropdown', property: 'laborType', name: 'Painting Labor Type', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'2.184': { dataType: 'dropdown', property: 'additionalLaborType', name: 'Additional Labor Type', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'2.185': { dataType: 'dropdown', property: 'paintExtras', name: 'Paint Extras', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'2.186': { dataType: 'dropdown', property: 'paintMaterialType', name: 'Material Type', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'2.839': { dataType: 'dropdown', property: 'soffitLightingType', name: 'Soffit Lighting Type', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'2.840': { dataType: 'dropdown', property: 'trackLightingType', name: 'Track Lighting Type', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'2.998': { dataType: 'string', property: 'notes', name: 'Siding notes', },
	'2.999': { dataType: 'number', property: 'discount', name: 'Siding discount', },

	'3.1': {dataType: 'number', property: 'yearsInHome', name: 'Years in home', },
	'3.3': { dataType: 'boolean', property: 'windowProblemMaintenance', name: 'Maintenance', },
	'3.4': { dataType: 'boolean', property: 'windowProblemRotten', name: 'Rotten', },
	'3.5': { dataType: 'boolean', property: 'windowProblemNonFunctional', name: 'Don’t Function', },
	'3.6': { dataType: 'boolean', property: 'windowProblemDamagedSills', name: 'Damaged Sills', },
	'3.7': { dataType: 'boolean', property: 'windowProblemNeedsPainting', name: 'Don’t Want to Paint', },
	'3.2': {dataType: 'number', property: 'ageOfWindows', name: 'Age of windows', },
	'3.8': { dataType: 'dropdown', property: 'stormWindows', name: 'Storm windows', },
	'3.9': { dataType: 'dropdown', property: 'productInterest', name: 'Product interest', },
	'3.10': { dataType: 'dropdown', property: 'energyEfficiency', name: 'Energy efficiency', },
	'3.11': { dataType: 'dropdown', property: 'warranty', name: 'Warranty', },
	'3.12': { dataType: 'dropdown', property: 'preferredTimeline', name: 'Preferred timeline', },
	'3.13': { dataType: 'dropdown', property: 'windowStyle', name: 'Window style', },
	'3.14': { dataType: 'dropdown', property: 'decidingFactor', name: 'Deciding factor', },

	'3.20': { dataType: 'string', property: 'windows', name: 'Window', allowMultiple: true, allowQuantityChange: true, defaultOne: true, },
	'3.21': { dataType: 'dropdown', property: 'roomName', name: 'Room name', parentId: '3.20', },
	'3.22': { dataType: 'string', property: 'roomDetails', name: 'Room details', parentId: '3.20', },
	'3.23': { dataType: 'dropdown', property: 'installType', name: 'Install type', parentId: '3.20', },
	'3.24': { dataType: 'number', property: 'width', name: 'Width', parentId: '3.20', },
	'3.25': { dataType: 'number', property: 'height', name: 'Height', parentId: '3.20', },
	'3.26': { dataType: 'number', property: 'ui', name: 'UI', parentId: '3.20', },
	'3.27': { dataType: 'dropdown', property: 'windowStyle', name: 'Window Style', parentId: '3.20', },
	'3.28': { dataType: 'dropdown', property: 'windowModel', name: 'Window Model', parentId: '3.20', },
	'3.29': { dataType: 'dropdown', property: 'windowStyleDetails', name: 'Window Style Detail', parentId: '3.20', },
	'3.30': { dataType: 'dropdown', property: 'glassType', name: 'Glass type', parentId: '3.20', },
	'3.52': { dataType: 'string', property: 'grid', name: 'Grid', parentId: '3.20', allowMultiple: true },
	'3.31': { dataType: 'dropdown', property: 'gridSize', name: 'Size', parentId: '3.52', },
	'3.32': { dataType: 'dropdown', property: 'gridPattern', name: 'Pattern', parentId: '3.52', },
	'3.33': { dataType: 'dropdown', property: 'gridColor', name: 'Color', parentId: '3.52', },
	'3.34': { dataType: 'dropdown', property: 'interiorColor', name: 'Interior Color', parentId: '3.20', },
	'3.69': { dataType: 'dropdown', property: 'interiorBoxFinished', name: 'Finished box interior', parentId: '3.20', },
	'3.93': { dataType: 'string', property: 'interiorTrim', name: 'Interior trim', parentId: '3.20', allowMultiple: true, defaultOne: true, },
	'3.36': { dataType: 'dropdown', property: 'interiorTrimType', name: 'Type', parentId: '3.93', },
	'3.37': { dataType: 'dropdown', property: 'interiorTrimColor', name: 'Color', parentId: '3.93', },
	'3.38': { dataType: 'dropdown', property: 'exteriorColor', name: 'Exterior color', parentId: '3.20', },
	'3.90': { dataType: 'string', property: 'exteriorTrim', name: 'Exterior trim', parentId: '3.20', allowMultiple: true, defaultOne: true, },
	'3.40': { dataType: 'dropdown', property: 'exteriorTrimType', name: 'Type', parentId: '3.90', },
	'3.41': { dataType: 'dropdown', property: 'exteriorTrimColor', name: 'Color', parentId: '3.90', },
	'3.42': { dataType: 'dropdown', property: 'hardware', name: 'Hardware', parentId: '3.20', },
	'3.43': { dataType: 'dropdown', property: 'screen', name: 'Screen', parentId: '3.20', },
	'3.44': { dataType: 'dropdown', property: 'swing', name: 'Swing', parentId: '3.20', defaultValue: null, },
	'3.45': { dataType: 'dropdown', property: 'operatingSash', name: 'Operating Sash', parentId: '3.20', defaultValue: null, },
	'3.46': { dataType: 'dropdown', property: 'blinds', name: 'Blinds', parentId: '3.20', defaultValue: null, },
	'3.47': { dataType: 'boolean', property: 'footLock', name: 'Foot lock', parentId: '3.20', },
	'3.48': { dataType: 'dropdown', property: 'weld', name: 'Weld', parentId: '3.20', defaultValue: null, },
	'3.49': { dataType: 'dropdown', property: 'roofOption', name: 'Roof option', parentId: '3.20', defaultValue: null, },
	'3.50': { dataType: 'number', property: 'jambDepth', name: 'Jamb depth', parentId: '3.20', },
	'3.51': { dataType: 'dropdown', property: 'obscureSash', name: 'Obscure sash', parentId: '3.20', defaultValue: null, },

	'3.65': { dataType: 'string', property: 'interiorTrim', name: 'Interior Trim', allowMultiple: true, defaultOne: true, },
	'3.66': { dataType: 'dropdown', property: 'interiorTrimType', name: 'Type', parentId: '3.65', units: 'PC', allowQuantityChange: true, },
	'3.67': { dataType: 'dropdown', property: 'interiorTrimColor', name: 'Color', parentId: '3.65', },
	'3.68': { dataType: 'number', property: 'interiorTrimLength', name: 'Length', parentId: '3.65', units: 'FT', },
	'3.70': { dataType: 'string', property: 'exteriorTrim', name: 'Exterior Trim', allowMultiple: true, defaultOne: true, },
	'3.71': { dataType: 'dropdown', property: 'exteriorTrimType', name: 'Type', parentId: '3.70', units: 'PC', allowQuantityChange: true, },
	'3.72': { dataType: 'dropdown', property: 'exteriorTrimColor', name: 'Color', parentId: '3.70', },
	'3.73': { dataType: 'number', property: 'exteriorTrimLength', name: 'Length', parentId: '3.70', units: 'FT', },

	'3.60': { dataType: 'boolean', property: 'setupFeeStainPaint', name: 'Stain / paint set up fee' },
	'3.61': { dataType: 'boolean', property: 'setupFeeStainPaintCustom', name: 'Custom stain / paint set up fee' },
	'3.62': { dataType: 'number', property: 'leadSafeRemoval', name: 'Lead safe removal' },
	'3.88': { dataType: 'number', property: 'secondStoryWindows', name: 'Second story windows' },
	'3.63': { dataType: 'boolean', property: 'smallJobFee', name: 'Small Job Fee' },
	'3.89': { dataType: 'dropdown', property: 'caulk', name: 'Caulk', allowMultiple: true, defaultOne: true, allowQuantityChange: true },
	'3.91': { dataType: 'number', property: 'trimNails', name: 'Trim nails' },
	'3.92': { dataType: 'number', property: 'sprayFoam', name: 'Spray foam' },
	'3.64': { dataType: 'string', property: 'custom', name: 'Custom work', allowPriceChange: true, allowMultiple: true, allowQuantityChange: true, },
	'3.136': { dataType: 'boolean', property: 'permit', name: 'Windows & Doors permit', },
	'3.800': { dataType: 'dropdown', property: 'deliveryDistance', name: 'Delivery / Distance', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'3.74': { dataType: 'dropdown', property: 'woWindowLine', name: 'Window Line', },
	'3.75': { dataType: 'number', property: 'woTotalWindows', name: 'Total windows', },
	'3.76': { dataType: 'number', property: 'woTotalPatioDoors', name: 'Total patio doors', },
	'3.77': { dataType: 'number', property: 'woTotalServiceDoors', name: 'Total entry/service doors', },
	'3.78': { dataType: 'number', property: 'woTotalStormDoors', name: 'Total storm doors', },
	'3.79': { dataType: 'number', property: 'woTotalGlassBlockWindows', name: 'Total glass block windows', },
	'3.80': { dataType: 'dropdown', property: 'woWindowInstallType', name: 'Window install type', },
	'3.81': { dataType: 'dropdown', property: 'woInteriorColor', name: 'Interior color', },
	'3.82': { dataType: 'dropdown', property: 'woExteriorColor', name: 'Exterior color', },
	'3.83': { dataType: 'dropdown', property: 'woHardwareColor', name: 'Hardware color', },
	'3.84': { dataType: 'dropdown', property: 'woTrimCoilColor', name: 'Trim coil color', },
	'3.85': { dataType: 'string', property: 'woTrimColor', name: 'Trim color', },
	'3.86': { dataType: 'dropdown', property: 'woCasingStyle', name: 'Casing style', },
	'3.87': { dataType: 'string', property: 'stainPaintColor', name: 'Stain / paint color', },

	'3.998': { dataType: 'string', property: 'notes', name: 'Window notes', },
	'3.999': { dataType: 'number', property: 'discount', name: 'Window discount', },

	'4.1': { dataType: 'dropdown', property: 'gutterAreaOfWork', name: 'Gutter area of work', },

	'4.41': { dataType: 'number', property: 'totalGutterLength', name: 'Total gutter length', units: 'FT', },
	'4.42': { dataType: 'number', property: 'totalGutterCorners', name: 'Total corners', },
	'4.43': { dataType: 'number', property: 'gutterWasteFactorForMaterial', name: 'Waste factor', units: '%', },
	'4.44': { dataType: 'number', property: 'gutterMaterialTotal', name: 'Material total', units: 'FT', },
	'4.2': { dataType: 'string', property: 'gutter', name: 'Gutter', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'4.3': { dataType: 'dropdown', property: 'gutterType', name: 'Type', parentId: '4.2', units: 'FT', allowQuantityChange: true, },
	'4.4': { dataType: 'dropdown', property: 'gutterColor', name: 'Color', parentId: '4.2', },
	'4.5': { dataType: 'dropdown', property: 'gutterInstallType', name: 'Gutter install type', parentId: '4.2', },
	'4.28': { dataType: 'string', property: 'gutterProtection', name: 'Gutter protection', allowMultiple: true, defaultOne: true, },
	'4.27': { dataType: 'number', property: 'gutterProtectionLength', name: 'Length', units: 'FT', parentId: '4.28', },
	'4.6': { dataType: 'dropdown', property: 'gutterProtectionType', name: 'Type', units: 'PC', allowQuantityChange: true, parentId: '4.28', },
	'4.39': { dataType: 'number', property: 'gutterCutDown', name: 'Cut down high-back gutters', units: 'FT' },
	'4.7': { dataType: 'dropdown', property: 'gutterCorners', name: 'Gutter corners', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'4.8': { dataType: 'number', property: 'downspoutQuantity', name: 'Downspout quantity', },
	'4.9': { dataType: 'string', property: 'downspout', name: 'Downspout', units: 'FT', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'4.10': { dataType: 'dropdown', property: 'downspoutType', name: 'Type', parentId: '4.9', },
	'4.11': { dataType: 'dropdown', property: 'downspoutColor', name: 'Color', parentId: '4.9', },
	'4.12': { dataType: 'dropdown', property: 'downspoutLocation', name: 'Downspout location', },
	'4.40': { dataType: 'dropdown', property: 'downspoutElbows', name: 'Downspout elbows', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'4.23': { dataType: 'string', property: 'custom', name: 'Custom work', allowPriceChange: true, allowMultiple: true, allowQuantityChange: true, },
	'4.24': { dataType: 'string', property: 'woodReplacement', name: 'Wood Replacement', units: 'PC', },
	'4.25': { dataType: 'number', property: 'woodReplacementArea', name: 'Wood Replacement Area', units: 'FT', parentId: '4.24', },
	'4.26': { dataType: 'dropdown', property: 'woodReplacementMaterial', name: 'Wood Replacement Material', units: 'PC', parentId: '4.24', allowQuantityChange: true, },
	'4.29': { dataType: 'dropdown', property: 'gutterAccessories', name: 'Gutter Accessories', units: 'EA', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	
	'4.136': { dataType: 'boolean', property: 'permit', name: 'Gutter permit', },
	'4.839': { dataType: 'dropdown', property: 'soffitLightingType', name: 'Soffit Lighting Type', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'4.840': { dataType: 'dropdown', property: 'trackLightingType', name: 'Track Lighting Type', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'4.998': { dataType: 'string', property: 'notes', name: 'Gutter notes', },
	'4.999': { dataType: 'number', property: 'discount', name: 'Gutter discount', },

	'7.13': { dataType: 'dropdown', property: 'trimAreaOfWork', name: 'Trim area of work', },

	'7.24': { dataType: 'string', property: 'standardSoffit', name: 'Standard soffit', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'7.25': { dataType: 'number', property: 'standardSoffitDepth', name: 'Depth', parentId: '7.24', units: 'IN', },
	'7.26': { dataType: 'number', property: 'standardSoffitLength', name: 'Length', parentId: '7.24', units: 'FT', },
	'7.27': { dataType: 'string', property: 'standardSoffitColor', name: 'Color', parentId: '7.24', },
	'7.28': { dataType: 'dropdown', property: 'standardSoffitLabor', name: 'Labor', parentId: '7.24', },
	'7.29': { dataType: 'string', property: 'hiddenVentSoffit', name: 'Hidden vent soffit', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'7.30': { dataType: 'number', property: 'hiddenVentSoffitDepth', name: 'Depth', parentId: '7.29', units: 'IN', },
	'7.31': { dataType: 'number', property: 'hiddenVentSoffitLength', name: 'Length', parentId: '7.29', units: 'FT', },
	'7.32': { dataType: 'string', property: 'hiddenVentSoffitColor', name: 'Color', parentId: '7.29', },
	'7.33': { dataType: 'dropdown', property: 'hiddenVentSoffitLabor', name: 'Labor', parentId: '7.29', },
	'7.60': { dataType: 'string', property: 'porchSoffit', name: 'Porch soffit', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'7.61': { dataType: 'number', property: 'porchSoffitDepth', name: 'Depth', parentId: '7.60', units: 'FT', },
	'7.62': { dataType: 'number', property: 'porchSoffitLength', name: 'Length', parentId: '7.60', units: 'FT', },
	'7.63': { dataType: 'string', property: 'porchSoffitColor', name: 'Color', parentId: '7.60', },
	'7.64': { dataType: 'dropdown', property: 'porchSoffitLabor', name: 'Labor', parentId: '7.60', },
	'7.14': { dataType: 'string', property: 'soffit', name: 'Soffit', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'7.15': { dataType: 'dropdown', property: 'soffitType', name: 'Type', parentId: '7.14', },
	'7.16': { dataType: 'dropdown', property: 'soffitColor', name: 'Color', parentId: '7.14', },
	'7.34': { dataType: 'dropdown', property: 'jChannel', name: 'J channel', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'7.45': { dataType: 'string', property: 'compositeSoffit', name: 'Composite soffit', allowMultiple: true, defaultOne: true, },
	'7.49': { dataType: 'number', property: 'compositeSoffitDepth', name: 'Depth', parentId: '7.45', units: 'IN', },
	'7.46': { dataType: 'dropdown', property: 'compositeSoffitLength', name: 'Length', parentId: '7.45', units: 'FT', allowQuantityChange: true, },
	'7.47': { dataType: 'dropdown', property: 'compositeSoffitType', name: 'Type', parentId: '7.45', units: 'PC', allowQuantityChange: true, },
	'7.48': { dataType: 'dropdown', property: 'compositeSoffitColor', name: 'Color', parentId: '7.45', },
	'7.137': { dataType: 'dropdown', property: 'cutSoffitVent', name: 'Cut in soffit vent', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	
	'7.41': { dataType: 'string', property: 'fasciaWrap', name: 'Aluminum fascia', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'7.42': { dataType: 'dropdown', property: 'fasciaWrapWidth', name: 'Width', parentId: '7.41', },
	'7.43': { dataType: 'dropdown', property: 'fasciaWrapColor', name: 'Color', parentId: '7.41', },
	'7.17': { dataType: 'string', property: 'fascia', name: 'Fascia', allowMultiple: true, defaultOne: true, },
	'7.18': { dataType: 'dropdown', property: 'fasciaType', name: 'Type', parentId: '7.17', units: 'PC', allowQuantityChange: true, },
	'7.19': { dataType: 'dropdown', property: 'fasciaColor', name: 'Color', parentId: '7.17', },
	'7.44': { dataType: 'number', property: 'fasciaLength', name: 'Length', parentId: '7.17', units: 'FT', },

	'7.20': { dataType: 'string', property: 'compositeTrim', name: 'Composite trim', allowMultiple: true, defaultOne: true, },
	'7.21': { dataType: 'dropdown', property: 'compositeTrimSize', name: 'Size', parentId: '7.20', units: 'PC', allowQuantityChange: true, },
	'7.50': { dataType: 'dropdown', property: 'compositeTrimType', name: 'Type', parentId: '7.20', },
	'7.22': { dataType: 'dropdown', property: 'compositeTrimColor', name: 'Color', parentId: '7.20', },
	'7.40': { dataType: 'number', property: 'compositeTrimLength', name: 'Length', parentId: '7.20', units: 'FT', },

	'7.35': { dataType: 'string', property: 'trimCoil', name: 'Trim coil', allowMultiple: true, defaultOne: true, units: 'RL', allowQuantityChange: true, },
	'7.36': { dataType: 'dropdown', property: 'trimCoilType', name: 'Type', parentId: '7.35', },
	'7.37': { dataType: 'dropdown', property: 'trimCoilColor', name: 'Color', parentId: '7.35', },
	'7.39': { dataType: 'string', property: 'customWrap', name: 'Custom wrap', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'7.54': { dataType: 'dropdown', property: 'customWrapType', name: 'Type', parentId: '7.39', },
	'7.55': { dataType: 'dropdown', property: 'customWrapColor', name: 'Color', parentId: '7.39' },

	'7.51': { dataType: 'string', property: 'nails', name: 'Nails', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'7.38': { dataType: 'dropdown', property: 'nailsType', name: 'Type', parentId: '7.51' },
	'7.52': { dataType: 'dropdown', property: 'nailsColor', name: 'Color', parentId: '7.51' },
	'7.2': { dataType: 'dropdown', property: 'setupFee', name: 'Setup fee', allowQuantityChange: true, },
	'7.53': { dataType: 'dropdown', property: 'caulk', name: 'Caulk', units: 'TB', allowQuantityChange: true, allowMultiple: true, defaultOne: true },
	'7.23': { dataType: 'string', property: 'custom', name: 'Custom work', allowPriceChange: true, allowMultiple: true, allowQuantityChange: true, },
	'7.136': { dataType: 'boolean', property: 'permit', name: 'Trim permit', },

	'7.183': { dataType: 'dropdown', property: 'laborType', name: 'Painting Labor Type', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'7.184': { dataType: 'dropdown', property: 'additionalLaborType', name: 'Additional Labor Type', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'7.185': { dataType: 'dropdown', property: 'paintExtras', name: 'Paint Extras', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'7.186': { dataType: 'dropdown', property: 'paintMaterialType', name: 'Material Type', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'7.839': { dataType: 'dropdown', property: 'soffitLightingType', name: 'Soffit Lighting Type', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'7.840': { dataType: 'dropdown', property: 'trackLightingType', name: 'Track Lighting Type', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'7.998': { dataType: 'string', property: 'notes', name: 'Trim notes', },
	'7.999': { dataType: 'number', property: 'discount', name: 'Trim discount', },

	'5.1': { dataType: 'dropdown', property: 'replaceShingles', name: 'Replace shingles', allowQuantityChange: true, },
	'5.2': { dataType: 'dropdown', property: 'replaceShinglesUnderlayment', name: 'Replace shingles + underlayment', allowQuantityChange: true, },
	'5.63': { dataType: 'dropdown', property: 'replaceShinglesUnderlaymentDecking', name: 'Replace shingles + underlayment + decking', allowQuantityChange: true, },
	'5.64': { dataType: 'dropdown', property: 'replaceRidge', name: 'Replace ridge cap shingles', allowQuantityChange: true, },
	'5.65': { dataType: 'dropdown', property: 'replaceValley', name: 'Replace valley', allowQuantityChange: true, },
	'5.66': { dataType: 'dropdown', property: 'replaceButterflyValley', name: 'Replace butterfly valley (shingles + membrane)', allowQuantityChange: true, },
	'5.67': { dataType: 'dropdown', property: 'replaceDormerIntersection', name: 'Replace dormer intersection (shingles + ice barrier + flashing)', allowQuantityChange: true, },
	'5.68': { dataType: 'dropdown', property: 'replaceRoofToWallIntersection', name: 'Replace roof to wall intersection (shingles + underlayment + flashing)', allowQuantityChange: true, },

	'5.70': { dataType: 'dropdown', property: 'decking', name: 'Decking', units: 'SH', allowQuantityChange: true, },
	'5.71': { dataType: 'dropdown', property: 'replaceDeckBoards', name: 'Replace deck boards', units: 'FT', allowQuantityChange: true, },
	'5.72': { dataType: 'dropdown', property: 'iceBarrier', name: 'Ice barrier', units: 'RL', allowQuantityChange: true, },
	'5.73': { dataType: 'dropdown', property: 'underlayment', name: 'Underlayment', units: 'RL', allowQuantityChange: true, },
	'5.74': { dataType: 'dropdown', property: 'starters', name: 'Starters', units: 'BND', allowQuantityChange: true, },
	'5.75': { dataType: 'dropdown', property: 'hipRidge', name: 'Hip & ridge', units: 'BND', allowQuantityChange: true, },
	'5.76': { dataType: 'string', property: 'valleyFlashing', name: 'Valleys', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.77': { dataType: 'dropdown', property: 'valleyFlashingColor', name: 'Color', parentId: '5.76', },
	'5.78': { dataType: 'dropdown', property: 'valleyFlashingType', name: 'Type', parentId: '5.76', },
	'5.79': { dataType: 'string', property: 'gutterFlashing', name: 'Gutter apron', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.80': { dataType: 'dropdown', property: 'gutterFlashingColor', name: 'Color', parentId: '5.79', },
	'5.81': { dataType: 'dropdown', property: 'gutterFlashingType', name: 'Type', parentId: '5.79', },
	'5.82': { dataType: 'string', property: 'dripEdge', name: 'Drip edge', units: 'PC', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.83': { dataType: 'dropdown', property: 'dripEdgeColor', name: 'Color', parentId: '5.82', },
	'5.84': { dataType: 'dropdown', property: 'dripEdgeType', name: 'Type', parentId: '5.82', },
	'5.90': { dataType: 'string', property: 'counterFlashing', name: 'Counter flashing', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.57': { dataType: 'dropdown', property: 'counterFlashingType', name: 'Type', parentId: '5.90', units: 'FT', },
	'5.58': { dataType: 'dropdown', property: 'counterFlashingColor', name: 'Color', parentId: '5.90', },
	'5.59': { dataType: 'dropdown', property: 'headFlashing', name: 'Head flashing', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.85': { dataType: 'string', property: 'stepFlashing', name: 'Step flashing', units: 'BND', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.86': { dataType: 'dropdown', property: 'stepFlashingColor', name: 'Color', parentId: '5.85', },
	'5.87': { dataType: 'dropdown', property: 'stepFlashingType', name: 'Type', parentId: '5.85', },
	'5.88': { dataType: 'dropdown', property: 'package', name: 'Shingle type', allowQuantityChange: true, units: 'SQ', },
	'5.89': { dataType: 'dropdown', property: 'shingleColor', name: 'Shingle color', },

	'5.60': { dataType: 'dropdown', property: 'pvcBoot', name: 'PVC pipe flashing', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.61': { dataType: 'dropdown', property: 'leadBoot', name: 'Lead pipe flashing', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.62': { dataType: 'dropdown', property: 'zipperBoot', name: 'Zipper pipe flashing', allowQuantityChange: true, },
	'5.8': { dataType: 'number', property: 'kitchenExhaustFlashing', name: 'Kitchen exhaust flashing', },
	'5.9': { dataType: 'number', property: 'bathExhaustFlashing', name: 'Bathroom exhaust flashing', },

	'5.3': { dataType: 'dropdown', property: 'chimneyFlashing', name: 'Chimney flashing', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.4': { dataType: 'dropdown', property: 'chimneyFlashingColor', name: 'Chimney flashing color', units: 'FT', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.5': { dataType: 'dropdown', property: 'chimneyChaseCap', name: 'Chimney chase cap', allowQuantityChange: true, },
	'5.6': { dataType: 'dropdown', property: 'tuckPointChimney', name: 'Tuck point chimney', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.7': { dataType: 'number', property: 'chimneyMasonrySealer', name: 'Masonry sealer', },

	'5.12': { dataType: 'string', property: 'flatRoof', name: 'Flat roofing', units: 'SQ FT', allowQuantityChange: true, },
	'5.13': { dataType: 'dropdown', property: 'flatRoofType', name: 'Type', parentId: '5.12', },
	'5.14': { dataType: 'dropdown', property: 'flatRoofColor', name: 'Color', parentId: '5.12', },
	'5.15': { dataType: 'number', property: 'tearOff', name: 'Tear-off flat roof', units: 'SQ FT', },
	'5.16': { dataType: 'number', property: 'terminationBars', name: 'Flat roof termination bar', },
	'5.17': { dataType: 'number', property: 'metalEdgeNarrow', name: 'Flat roof < 4″ Metal Edge', },
	'5.18': { dataType: 'number', property: 'metalEdgeWide', name: 'Flat roof > 4″ Metal Edge', },
	'5.19': { dataType: 'dropdown', property: 'insulation', name: 'Flat roof insulation type', units: 'SQ FT', allowQuantityChange: true, },
	'5.20': { dataType: 'number', property: 'drainsReUse', name: 'Re-use existing flat roof drains', },
	'5.21': { dataType: 'number', property: 'drainsNew', name: 'Install new 4″ flat roof drains', },
	'5.22': { dataType: 'number', property: 'penetrations', name: 'Flat roof penetrations', },
	'5.23': { dataType: 'dropdown', property: 'railingSections', name: 'Flat roof railing type', allowQuantityChange: true, },
	'5.24': { dataType: 'dropdown', property: 'railingPosts', name: 'Flat roof railing posts', allowQuantityChange: true, },

	'5.25': { dataType: 'dropdown', property: 'curbMountedReplace', name: 'Curb mounted skylight', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.26': { dataType: 'dropdown', property: 'deckMountedReplace', name: 'Deck mounted skylight', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.27': { dataType: 'dropdown', property: 'sunTunnelReplace', name: 'Sun tunnel skylight', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.28': { dataType: 'number', property: 'curbMountedReFlash', name: 'Re-flash curb mounted skylight', },
	'5.29': { dataType: 'number', property: 'deckMountedReFlash', name: 'Re-flash deck mounted skylight', },
	'5.30': { dataType: 'number', property: 'curbMountedCutIn', name: 'Cut in curb mounted skylight', },
	'5.31': { dataType: 'number', property: 'deckMountedCutIn', name: 'Cut in deck mounted skylight', },
	'5.32': { dataType: 'number', property: 'curbMountedSolarBlinds', name: 'Solar blinds for curb mounted skylight', },
	'5.33': { dataType: 'number', property: 'deckMountedSolarBlinds', name: 'Solar blinds for deck mounted skylight', },
	'5.92': { dataType: 'number', property: 'buildCurb', name: 'Build curb', },

	'5.36': { dataType: 'dropdown', property: 'fasciaIntakeVent', name: 'Fascia intake vent', units: 'FT', allowQuantityChange: true, },
	'5.37': { dataType: 'dropdown', property: 'louveredSoffitVentAluminum', name: 'Louvered 4″x16″ intake vent', allowQuantityChange: true, },
	'5.38': { dataType: 'dropdown', property: 'louveredSoffitVentWood', name: 'Louvered 8″x16″ intake vent', allowQuantityChange: true, },
	'5.39': { dataType: 'dropdown', property: 'edgeVent', name: 'EdgeVent', units: 'FT', allowQuantityChange: true, },
	'5.40': { dataType: 'dropdown', property: 'ridgeExhaustVent', name: 'Ridge vent', units: 'FT', allowQuantityChange: true, },
	'5.41': { dataType: 'dropdown', property: 'powerExhaustVent', name: 'Power vent', allowQuantityChange: true, },
	'5.42': { dataType: 'dropdown', property: 'boxExhaustVent', name: 'Box vent', allowQuantityChange: true, },
	'5.10': { dataType: 'dropdown', property: 'kitchenExhaustVent', name: 'Kitchen exhaust', allowQuantityChange: true, },
	'5.11': { dataType: 'dropdown', property: 'bathExhaustVent', name: 'Bathroom exhaust', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'5.43': { dataType: 'boolean', property: 'initialSetUp', name: 'Repair initial set-up', },
	'5.44': { dataType: 'boolean', property: 'highRoof', name: 'High roof charge', },
	'5.45': { dataType: 'boolean', property: 'steepRoof', name: 'Steep roof charge', },
	'5.47': { dataType: 'boolean', property: 'dumpsterNeeded', name: 'Dumpster needed', },
	'5.69': { dataType: 'string', property: 'dumpster', name: 'Dumpster', allowMultiple: true, defaultOne: true, },
	'5.48': { dataType: 'dropdown', property: 'dumpsterType', name: 'Type', parentId: '5.69', },
	'5.49': { dataType: 'dropdown', property: 'dumpsterLocation', name: 'Location', parentId: '5.69', },
	'5.91': { dataType: 'dropdown', property: 'dumpsterLabor', name: 'Dumpster labor', units: 'SQ', allowQuantityChange: true, },
	'5.50': { dataType: 'number', property: 'totalLaborHours', name: 'Total labor hours', },
	'5.53': { dataType: 'dropdown', property: 'roofingCement', name: 'Roofing cement', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.52': { dataType: 'dropdown', property: 'caulk', name: 'Caulk', units: 'TB', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'5.54': { dataType: 'string', property: 'trimCoil', name: 'Trim coil', allowMultiple: true, defaultOne: true, units: 'RL', allowQuantityChange: true, },
	'5.55': { dataType: 'dropdown', property: 'trimCoilType', name: 'Type', parentId: '5.54', },
	'5.56': { dataType: 'dropdown', property: 'trimCoilColor', name: 'Color', parentId: '5.54', },
	'5.51': { dataType: 'string', property: 'custom', name: 'Custom work', allowPriceChange: true, allowMultiple: true, allowQuantityChange: true, },
	'5.136': { dataType: 'boolean', property: 'permit', name: 'Repairs permit', },
	'5.800': { dataType: 'dropdown', property: 'deliveryDistance', name: 'Delivery / Distance', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'5.183': { dataType: 'dropdown', property: 'laborType', name: 'Painting Labor Type', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'5.184': { dataType: 'dropdown', property: 'additionalLaborType', name: 'Additional Labor Type', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'5.185': { dataType: 'dropdown', property: 'paintExtras', name: 'Paint Extras', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },
	'5.186': { dataType: 'dropdown', property: 'paintMaterialType', name: 'Material Type', allowQuantityChange: true, allowMultiple: true, defaultOne: true, },

	'5.998': { dataType: 'string', property: 'notes', name: 'Repair notes', },
	'5.999': { dataType: 'number', property: 'discount', name: 'Repair discount', },

	'6.1': { dataType: 'number', property: 'yearsInHome', name: 'Years in home', },
	'6.2': { dataType: 'number', property: 'ageOfInsulation', name: 'Age of insulation', },
	'6.3': { dataType: 'dropdown', property: 'preferredTimeline', name: 'Preferred timeline', },
	'6.4': { dataType: 'dropdown', property: 'decidingFactor', name: 'Deciding factor', },
	'6.5': { dataType: 'boolean', property: 'recentlyAddedInsulation', name: 'Recently added insulation', },
	'6.6': { dataType: 'boolean', property: 'moisturePresent', name: 'Moisture present', },
	'6.7': { dataType: 'dropdown', property: 'mold', name: 'Mold', },
	'6.8': { dataType: 'dropdown', property: 'ventilation', name: 'Ventilation', },
	'6.9': { dataType: 'dropdown', property: 'existingRValue', name: 'Existing R-Value', },
	'6.10': { dataType: 'dropdown', property: 'existingInsulationType', name: 'Existing insulation type', },
	'6.11': { dataType: 'boolean', property: 'existingAirSealing', name: 'Existing air sealing', },
	'6.12': { dataType: 'string', property: 'previousInsulationProviders', name: 'Previous insulation providers', allowMultiple: true, defaultOne: true, },

	'6.13': { dataType: 'dropdown', property: 'homeEnergyAssessments', name: 'Home energy assessments', allowMultiple: true, defaultOne: true, },

	'6.16': { dataType: 'dropdown', property: 'airSealingPackage', name: 'Air sealing package', },
	'6.34': { dataType: 'dropdown', property: 'atticAccess', name: 'Attic access', },
	'6.17': { dataType: 'number', property: 'sealRecessedLightIcRated', name: 'Recessed light IC rated', },
	'6.18': { dataType: 'number', property: 'sealRecessedLightNotIcRated', name: 'Recessed light not IC rated', },
	'6.19': { dataType: 'number', property: 'sealDroppedSoffits', name: 'Dropped soffits', },
	'6.20': { dataType: 'number', property: 'sealTopPlates', name: 'Top plates', },
	'6.21': { dataType: 'number', property: 'sealKneeWallFloorLine', name: 'Knee wall floor line', },
	'6.22': { dataType: 'number', property: 'sealBasementBoxStill', name: 'Basement box still', },

	'6.25': { dataType: 'dropdown', property: 'openJoistInsulation', name: 'Open joist', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'6.26': { dataType: 'dropdown', property: 'kneeWallInsulation', name: 'Knee wall', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'6.27': { dataType: 'dropdown', property: 'houseWrap', name: 'House wrap', units: 'RL', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'6.28': { dataType: 'dropdown', property: 'densePackWallExteriorInsulation', name: 'Dense pack wall - exterior access', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'6.29': { dataType: 'dropdown', property: 'densePackWallInteriorInsulation', name: 'Dense pack wall - interior access', allowQuantityChange: true, },
	'6.30': { dataType: 'dropdown', property: 'closedFloorInsulation', name: 'Closed floor', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'6.31': { dataType: 'dropdown', property: 'kitchenExhaustVent', name: 'Kitchen exhaust', allowQuantityChange: true, },
	'6.32': { dataType: 'dropdown', property: 'bathExhaustVent', name: 'Bathroom exhaust', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'6.33': { dataType: 'string', property: 'custom', name: 'Custom work', allowPriceChange: true, allowMultiple: true, allowQuantityChange: true, },
	'6.136': { dataType: 'boolean', property: 'permit', name: 'Insulation permit', },
	'6.800': { dataType: 'dropdown', property: 'deliveryDistance', name: 'Delivery / Distance', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },
	'6.801': { dataType: 'dropdown', property: 'insulationAccesories', name: 'Insulation Accesories', allowMultiple: true, defaultOne: true, allowQuantityChange: true, },

	'6.998': { dataType: 'string', property: 'notes', name: 'Insulation notes', },
	'6.999': { dataType: 'number', property: 'discount', name: 'Insulation discount', },
};

const parentIds = new Set();
for (const fieldId of Object.keys(fieldInfo)) {
    const field = fieldInfo[fieldId];
    if (field.dataType === 'number') {
        field.allowQuantityChange = true;
    }
    if (!Array.isArray(field.childIds)) {
        field.childIds = [];
    }
    const parentId = fieldInfo[fieldId].parentId;
    if (parentId) {
        parentIds.add(parentId);
        const parent = fieldInfo[parentId];
        const childIds = Array.isArray(parent.childIds) ? parent.childIds : (parent.childIds = []);
        childIds.push(fieldId);
    }
}
for (const fieldId of Object.keys(fieldInfo)) {
    fieldInfo[fieldId].ancestorIds = getAncestorIds(fieldId);
    fieldInfo[fieldId].descendantIds = getDescendantIds(fieldId);
}
function getAncestorIds(fieldId) {
    const field = fieldInfo[fieldId];
    if (field.ancestorIds) return field.ancestorIds;
    const ancestorIds = new Set([fieldId]);
    if (field.parentId) {
        getAncestorIds(field.parentId).forEach((x) => ancestorIds.add(x));
    }
    return ancestorIds;
}
function getDescendantIds(fieldId) {
    const field = fieldInfo[fieldId];
    if (field.descendantIds) return field.descendantIds;
    const descendantIds = new Set([fieldId]);
    const childIds = field.childIds;
    if (Array.isArray(childIds)) {
        for (const childId of childIds) {
            getDescendantIds(childId).forEach((x) => descendantIds.add(x));
        }
    }
    return descendantIds;
}

export const mapping = {
    [EstimateSections.roofingQuestionnaire]: [
        '1.1',
        '1.2',
        '1.3',
        '1.4',
        '1.5',
        '1.6',
        '1.7',
        '1.8',
        '1.9',
        '1.10',
        '1.11',
        '1.12',
        '1.13',
        '1.14',
        '1.15',
        '1.16',
        '1.17',
        '1.18',
        '1.19',
        '1.20',
        '1.21',
        '1.22',
        '1.23',
        '1.24',
        '1.25',
        '1.26',
        '1.27',
        '1.28',
        '1.29',
        '1.30',
        '1.35',
    ],
    [EstimateSections.roofingDetails]: [
        '1.31',
        '1.171',
        '1.181',
        '1.166',
        '1.167',
        '1.176',
        '1.168',
        '1.169',
        '1.184',
        '1.48',
        '1.49',
        '1.50',
        '1.51',
        '1.177',
        '1.89',
        '1.43',
        '1.228',
        '1.58',
        '1.60',
        '1.63',
        '1.189',
        '1.37',
        '1.146',
    ],
    [EstimateSections.roofingDeckUnderlayment]: [
        '1.173',
        '1.53',
        '1.54',
        '1.55',
        '1.58',
        '1.37',
        '1.146',
        '1.36',
        '1.60',
        '1.63',
        '1.189',
    ],
    [EstimateSections.roofingFlashing]: [
        '1.143',
        '1.144',
        '1.145',
        '1.175',
        '1.70',
        '1.170',
        '1.72',
        '1.73',
        '1.74',
        '1.802',
    ],
    [EstimateSections.roofingChimneys]: ['1.75', '1.86', '1.79', '1.80', '1.82', '1.130'],
    [EstimateSections.roofingSkylight]: [
        '1.83',
        '1.85',
        '1.87',
        '1.88',
        '1.90',
        '1.91',
        '1.92',
        '1.93',
        '1.94',
        '1.182',
    ],
    [EstimateSections.roofingVentilation]: [
        '1.97',
        '1.77',
        '1.81',
        '1.98',
        '1.201',
        '1.163',
        '1.99',
        '1.100',
        '1.805',
        '1.806',
        '1.103',
        '1.104',
        '1.105',
        '1.106',
        '1.115',
    ],
    [EstimateSections.roofingFlatRoof]: [
        '1.210',
        '1.228',
        '1.114',
        '1.214',
        '1.216',
        '1.217',
        '1.218',
        '1.219',
        '1.220',
        '1.223',
        '1.221',
        '1.222',
        '1.227',
        '1.120',
        '1.123',
    ],
    [EstimateSections.roofingFascia]: ['1.832', '1.835'],
    [EstimateSections.roofingSoffit]: ['1.807', '1.812', '1.822', '1.825', '1.826', '1.817', '1.831'],
    [EstimateSections.roofingExteriorLighting]: ['1.839', '1.840'],
    [EstimateSections.roofingExtras]: [
        '1.126',
        '1.127',
        '1.129',
        '1.136',
        '1.137',
        '1.138',
        '1.142',
        '1.131',
        '1.174',
        '1.183',
        '1.800',
        '1.801',
    ],
    [EstimateSections.roofingPackage]: ['1.132', '1.134', '1.133', '1.57', '1.59', '1.61', '1.62', '1.152', '1.168'],
    [EstimateSections.roofingNotes]: ['1.998'],
    [EstimateSections.roofingDiscount]: ['1.999'],

    [EstimateSections.sidingQuestionnaire]: [
        '2.1',
        '2.2',
        '2.3',
        '2.4',
        '2.5',
        '2.6',
        '2.7',
        '2.8',
        '2.9',
        '2.10',
        '2.11',
        '2.12',
        '2.13',
        '2.14',
        '2.15',
        '2.16',
        '2.17',
        '2.18',
        '2.19',
        '2.20',
        '2.21',
        '2.22',
    ],
    [EstimateSections.sidingDetails]: [
        '2.23',
        '2.24',
        '2.28',
        '2.98',
        '2.99',
        '2.116',
        '2.29',
        '2.100',
        '2.111',
        '2.112',
        '2.30',
        '2.31',
        '2.114',
        '2.129',
        '2.125',
        '2.119',
        '2.122',
        '2.187',
        '2.841',
        '2.845',
        '2.847',
        '2.43',
        '2.46',
    ],
    [EstimateSections.sidingHouseWrap]: ['2.108', '2.130'],
    [EstimateSections.sidingAccessories]: ['2.841', '2.843', '2.845', '2.847', '2.39', '2.40', '2.41', '2.42'],
    [EstimateSections.sidingCorners]: ['2.43', '2.46'],
    [EstimateSections.sidingWraps]: ['2.49', '2.52', '2.55', '2.58', '2.651', '2.126'],
    [EstimateSections.sidingTrim]: ['2.61', '2.105'],
    [EstimateSections.sidingShutters]: ['2.66', '2.67'],
    [EstimateSections.sidingAccents]: ['2.70', '2.731', '2.118', '2.29', '2.100'],
    [EstimateSections.sidingDoors]: ['2.74', '2.78', '2.79'],
    [EstimateSections.sidingPainting]: ['2.183', '2.184', '2.185', '2.186'],
    [EstimateSections.sidingExteriorLighting]: ['2.839', '2.840'],
    [EstimateSections.sidingExtras]: ['2.83', '2.84', '2.85', '2.86', '2.82', '2.115', '2.136', '2.800'],
    [EstimateSections.sidingPackage]: ['2.88', '2.104', '2.89', '2.90'],
    [EstimateSections.sidingFascia]: ['2.832', '2.835'],
    [EstimateSections.sidingSoffit]: ['2.807', '2.812', '2.822', '2.825', '2.826', '2.817', '2.831'],
    [EstimateSections.sidingNotes]: ['2.998'],
    [EstimateSections.sidingDiscount]: ['2.999'],

    [EstimateSections.windowQuestionnaire]: [
        '3.1',
        '3.3',
        '3.4',
        '3.5',
        '3.6',
        '3.7',
        '3.2',
        '3.8',
        '3.9',
        '3.10',
        '3.11',
        '3.12',
        '3.13',
        '3.14',
    ],
    [EstimateSections.windowList]: ['3.20'],
    [EstimateSections.windowTrim]: ['3.65', '3.70'],
    [EstimateSections.windowProjectSpecs]: [
        '3.60',
        '3.61',
        '3.62',
        '3.88',
        '3.63',
        '3.64',
        '3.89',
        '3.91',
        '3.92',
        '3.136',
        '3.800',
    ],
    [EstimateSections.windowPackage]: ['3.20'],
    [EstimateSections.windowNotes]: ['3.998'],
    [EstimateSections.windowDiscount]: ['3.999'],

    [EstimateSections.gutterDetails]: ['4.1'],
    [EstimateSections.gutterGutters]: [
        '4.41',
        '4.42',
        '4.43',
        '4.44',
        '4.2',
        '4.28',
        '4.39',
        '4.7',
        '4.8',
        '4.9',
        '4.40',
        '4.12',
    ],
    [EstimateSections.gutterExteriorLighting]: ['4.839', '4.840'],
    [EstimateSections.gutterExtras]: ['4.23', '4.24', '4.136', '4.29'],
    [EstimateSections.gutterNotes]: ['4.998'],
    [EstimateSections.gutterDiscount]: ['4.999'],

    [EstimateSections.trimDetails]: ['7.13'],
    [EstimateSections.trimSoffit]: ['7.24', '7.29', '7.14', '7.34', '7.45', '7.60', '7.137'],
    [EstimateSections.trimFascia]: ['7.41', '7.17'],
    [EstimateSections.trimComposite]: ['7.20'],
    [EstimateSections.trimWraps]: ['7.35', '7.39'],
    [EstimateSections.trimPainting]: ['7.183', '7.184', '7.185', '7.186'],
    [EstimateSections.trimExteriorLighting]: ['7.839', '7.840'],
    [EstimateSections.trimExtras]: ['7.23', '7.51', '7.2', '7.53', '7.136'],
    [EstimateSections.trimNotes]: ['7.998'],
    [EstimateSections.trimDiscount]: ['7.999'],

    [EstimateSections.repairRoof]: [
        '5.1',
        '5.2',
        '5.63',
        '5.64',
        '5.65',
        '5.66',
        '5.67',
        '5.68',
        '5.88', // shingles (dropdown)
        '5.89', // shingleColor (dropdown)
        '5.71', // replaceDeckBoards (dropdown)
        '5.70', // decking (dropdown)
        '5.72', // iceBarrier (dropdown)
        '5.73', // underlayment (dropdown)
        '5.74', // starters (dropdown)
        '5.75', // hipRidge (dropdown)
    ],
    [EstimateSections.repairFlashing]: [
        '5.90',
        '5.59',
        '5.60',
        '5.61',
        '5.62',
        '5.8',
        '5.9',
        '5.82', // dripEdge (string)
        '5.79', // gutterFlashing (string)
        '5.76', // valleyFlashing (string)
        '5.85', // stepFlashing (string)
    ],
    [EstimateSections.repairChimney]: ['5.3', '5.4', '5.5', '5.6', '5.7'],
    [EstimateSections.repairFlatRoof]: [
        '5.12',
        '5.15',
        '5.16',
        '5.17',
        '5.18',
        '5.19',
        '5.20',
        '5.21',
        '5.22',
        '5.23',
        '5.24',
    ],
    [EstimateSections.repairSkylight]: ['5.25', '5.26', '5.27', '5.28', '5.29', '5.30', '5.31', '5.32', '5.33', '5.92'],
    [EstimateSections.repairVentilation]: ['5.36', '5.37', '5.38', '5.39', '5.40', '5.41', '5.42', '5.10', '5.11'],
    [EstimateSections.repairPainting]: ['5.183', '5.184', '5.185', '5.186'],
    [EstimateSections.repairExtras]: [
        '5.43',
        '5.44',
        '5.45',
        '5.47',
        '5.69',
        '5.91',
        '5.50',
        '5.51',
        '5.53',
        '5.52',
        '5.54',
        '5.136',
        '5.800',
    ],
    [EstimateSections.repairNotes]: ['5.998'],
    [EstimateSections.repairDiscount]: ['5.999'],

    [EstimateSections.insulationQuestionnaire]: [
        '6.1',
        '6.2',
        '6.3',
        '6.4',
        '6.5',
        '6.6',
        '6.7',
        '6.8',
        '6.9',
        '6.10',
        '6.11',
        '6.12',
    ],
    [EstimateSections.insulationPreTest]: ['6.13'],
    [EstimateSections.insulationAirSeal]: ['6.16', '6.34', '6.17', '6.18', '6.19', '6.20', '6.21', '6.22'],
    [EstimateSections.insulationDetails]: ['6.25', '6.26', '6.27', '6.28', '6.29', '6.30'],
    [EstimateSections.insulationExtras]: ['6.31', '6.32', '6.33', '6.136', '6.800', '6.801'],
    [EstimateSections.insulationNotes]: ['6.998'],
    [EstimateSections.insulationDiscount]: ['6.999'],
};

export const order = Object.keys(mapping).reduce((o, key) => {
    for (let i = 0; i < mapping[key].length; i++) {
        const fieldId = mapping[key][i];
        const section = EstimateSections.map[key];
        const workflow = EstimateWorkflows.map[section.workflowId];
        o[fieldId] = { order1: workflow.order, order2: section.order, order3: i };
    }
    return o;
}, {});

export const fieldsToCopyParentQuantityFromChild = [
    { parentFieldId: '7.24', childFieldId: '7.26' }, // standardSoffit <= standardSoffitLength
    { parentFieldId: '7.29', childFieldId: '7.31' }, // hiddenVentSoffit <= hiddenVentSoffitLength
    { parentFieldId: '7.60', childFieldId: '7.62' }, // porchSoffit <= porchSoffitLength,
    { parentFieldId: '4.2', childFieldId: '4.3' }, // gutter <= gutterType
    { parentFieldId: '1.807', childFieldId: '1.809' }, // standardSoffit <= standardSoffitLength
    { parentFieldId: '1.812', childFieldId: '1.814' }, // hiddenVentSoffit <= hiddenVentSoffitLength
    { parentFieldId: '1.817', childFieldId: '1.819' }, // porchSoffit <= porchSoffitLength,
    { parentFieldId: '2.807', childFieldId: '2.809' }, // standardSoffit <= standardSoffitLength
    { parentFieldId: '2.812', childFieldId: '2.814' }, // hiddenVentSoffit <= hiddenVentSoffitLength
    { parentFieldId: '2.817', childFieldId: '2.819' }, // porchSoffit <= porchSoffitLength,
];

export const fieldsToToggleParentQuantityFromChild = [
    { parentFieldId: '1.228', childFieldId: '1.213' }, // membrane <= membraneType
    { parentFieldId: '1.214', childFieldId: '1.215' }, // edgeDetail <= edgeDetailType
    { parentFieldId: '1.805', childFieldId: '1.101' }, // edgeVent <= edgeVentType
    { parentFieldId: '1.806', childFieldId: '1.102' }, // ridgeExhaustVent <= ridgeExhaustVentType
    { parentFieldId: '1.802', childFieldId: '1.804' }, // roofToWallTrim <= roofToWallTrimType
    { parentFieldId: '2.651', childFieldId: '2.65' }, // gableAccents <= gableAccentType
    { parentFieldId: '2.70', childFieldId: '2.71' }, // gableAccents <= gableAccentType
    { parentFieldId: '2.731', childFieldId: '2.73' }, // gableVent <= gableVentType
    { parentFieldId: '2.61', childFieldId: '2.63' }, // trim <= trimSize
    { parentFieldId: '2.108', childFieldId: '2.34' }, // houseWrap <= houseWrapType
    { parentFieldId: '2.130', childFieldId: '2.133' }, // houseInsulation <= houseInsulationType
    { parentFieldId: '2.119', childFieldId: '2.121' }, // sheathing <= sheathingType
    { parentFieldId: '2.122', childFieldId: '2.124' }, // boards <= boardType
    { parentFieldId: '3.65', childFieldId: '3.66' }, // interiorTrim <= interiorTrimSize
    { parentFieldId: '3.70', childFieldId: '3.71' }, // exteriorTrim <= exteriorTrimSize
    { parentFieldId: '4.24', childFieldId: '4.25' }, // woodReplacement <= woodReplacementArea
    { parentFieldId: '4.2', childFieldId: '4.3' }, // gutter <= gutterType
    { parentFieldId: '7.17', childFieldId: '7.18' }, // fascia <= fasciaType
    { parentFieldId: '7.20', childFieldId: '7.21' }, // compositeTrim <= compositeTrimSize
    { parentFieldId: '7.45', childFieldId: '7.47' }, // compositeSoffit <= compositeSoffitType
    { parentFieldId: '1.835', childFieldId: '1.837' }, // fascia <= fasciaType
    { parentFieldId: '1.826', childFieldId: '1.829' }, // compositeSoffit <= compositeSoffitType
    { parentFieldId: '2.835', childFieldId: '2.837' }, // fascia <= fasciaType
    { parentFieldId: '2.826', childFieldId: '2.829' }, // compositeSoffit <= compositeSoffitType
];
